/**
 * External Dependencies
 */
import React from "react";
import PropTypes from "prop-types";

/**
 * Internal Dependencies
 * - Styles
 * - Internal Components
 */
import "./dropdownsection.css";
import DropdownOption from "./dropdownoption";

/**
 * DropdownSection Component
 * @param {string} label - Label for the section.
 * @param {string} labelImage - Source URL for the label image.
 * @param {function} labelAction - Click handler function for the label.
 * @param {Array} options - List of options to display in the section.
 * @param {boolean} showBorder - Whether to show a border between sections.
 * @returns {JSX.Element} DropdownSection component
 */
const DropdownSection = ({
  label,
  labelImage,
  labelAction,
  options,
  showBorder,
}) => {
  return (
    <div className="dropdownsection-dropdownsection">
      {showBorder && <div className="dropdownsection-divider"></div>}
      <div className="dropdownsection-header">
        <span className="dropdownsection-label td2">{label}</span>
        <img
          src={labelImage}
          onClick={labelAction}
          className="dropdownsection-image img1"
        />
      </div>
      <div className="dropdownsection-dropdownlist">
        {options.map((option, index) => (
          <DropdownOption
            key={index}
            text={option.text}
            image={option.image}
            onClick={option.onClick}
            selected={option.selected}
          />
        ))}
      </div>
    </div>
  );
};

DropdownSection.propTypes = {
  label: PropTypes.string,
  labelImage: PropTypes.string,
  labelAction: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      image: PropTypes.string,
      onClick: PropTypes.func,
      selected: PropTypes.bool,
    }),
  ).isRequired,
  showBorder: PropTypes.bool,
};

export default DropdownSection;
