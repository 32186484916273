/**
 * External Dependencies
 */
import React from "react";
import PropTypes from "prop-types";

/**
 * Internal Components
 */
import SelectedVisitItem from "./selectedvisititem";
import UnselectedVisitItem from "./unselectedvisititem";

/**
 * Internal Dependencies
 * - Styles
 * - Redux
 * - Utilities
 */
import "./visititemlist.css";
import { useSelector, useDispatch } from "react-redux";
import { setCurrentState, addVisit, deleteVisit } from "../../../redux/actions";
import { generateId } from "../../../scripts/apiService";

/**
 * VisitItemList component for displaying a list of visit items.
 * @returns {JSX.Element} The VisitItemList component.
 */
const VisitItemList = () => {
  const dispatch = useDispatch();
  const currentVisitId = useSelector(
    (state) => state.globalData.currentState.currentVisitId,
  );
  const userData = useSelector((state) => state.globalData.userData);

  const today = new Date();
  today.setHours(0, 0, 0, 0); // Start of today

  /**
   * Parses the date string to create a Date object.
   * @param {string} dateString - The date string to parse.
   * @returns {Date} - The parsed Date object.
   */
  const parseDateString = (dateString) => {
    const [datePart, timePart] = dateString.split(" • ");
    const date = new Date(`${datePart}, ${timePart}`);
    return date;
  };

  /**
   * Formats the given date into a readable string.
   * @param {Date} date - The date to format.
   * @returns {string} - The formatted date string.
   */
  const formatDate = (date) => {
    return date.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
  };

  /**
   * Formats the visit time and audio time.
   * @param {object} visit - The visit object to format.
   * @returns {string} - The formatted time and audio time string.
   */
  const formatTimeAndAudio = (visit) => {
    const dateOptions = { hour: "numeric", minute: "numeric", hour12: true };
    const formattedTime = new Intl.DateTimeFormat("en-US", dateOptions).format(
      parseDateString(visit.visitCreatedDate),
    );
    const audioTime =
      visit.visitAudioTime > 0
        ? `${(visit.visitAudioTime / 60).toFixed(0)} min`
        : "Not started";
    return `${formattedTime} • ${audioTime}`;
  };

  const visitsByDate = userData.visits.reduce((acc, visit) => {
    const visitDate = parseDateString(visit.visitCreatedDate);
    const dateKey = formatDate(visitDate);
    if (!acc[dateKey]) {
      acc[dateKey] = [];
    }
    acc[dateKey].push(visit);
    return acc;
  }, {});

  /**
   * Renders the visit item based on its selection state.
   * @param {object} visit - The visit object to render.
   * @returns {JSX.Element} - The rendered visit item.
   */
  const renderVisitItem = (visit) =>
    visit._id === currentVisitId ? (
      <SelectedVisitItem
        key={visit._id}
        name={visit.visitName}
        date={formatTimeAndAudio(visit)}
        onClick={() => handleVisitClick(visit._id)}
        onTrashClick={() => {
          if (window.confirm("Are you sure you want to delete this visit?")) {
            handleTrashClick(visit._id);
          }
        }}
      />
    ) : (
      <UnselectedVisitItem
        key={visit._id}
        name={visit.visitName}
        date={formatTimeAndAudio(visit)}
        onClick={() => handleVisitClick(visit._id)}
        onTrashClick={() => {
          if (window.confirm("Are you sure you want to delete this visit?")) {
            handleTrashClick(visit._id);
          }
        }}
      />
    );

  /**
   * Handles the click event for selecting a visit.
   * @param {string} visitId - The ID of the selected visit.
   */
  const handleVisitClick = (visitId) => {
    const nextVisit = userData.visits.find((visit) => visit._id === visitId);
    dispatch(
      setCurrentState({
        currentVisitId: nextVisit._id,
        currentTab:
          nextVisit.visitFinished !== undefined
            ? nextVisit.visitFinished
              ? "NOTES"
              : "RECORD"
            : "NOTES",
        currentNoteId: "",
      }),
    );
    if (window.innerWidth <= 740) {
      dispatch(setCurrentState({ currentDisplaySidebar: false }));
    }
  };

  /**
   * Formats the given date into a readable string.
   * @param {Date} date - The date to format.
   * @returns {string} - The formatted date and time string.
   */
  const formatDateTime = (date) => {
    const dateOptions = { month: "long", day: "numeric", year: "numeric" };
    const timeOptions = { hour: "numeric", minute: "numeric", hour12: true };
    const formattedDate = new Intl.DateTimeFormat("en-US", dateOptions).format(
      date,
    );
    const formattedTime = new Intl.DateTimeFormat("en-US", timeOptions).format(
      date,
    );
    return `${formattedDate} • ${formattedTime}`;
  };

  /**
   * Handles the click event for deleting a visit.
   * @param {string} visitId - The ID of the visit to delete.
   */
  const handleTrashClick = (visitId) => {
    if (userData.visits.length > 1) {
      if (visitId === currentVisitId) {
        const nextVisit = userData.visits.find(
          (visit) => visit._id !== visitId,
        );
        dispatch(
          setCurrentState({
            currentVisitId: nextVisit._id,
            currentTab: nextVisit.visitTranscript === "" ? "RECORD" : "NOTES",
            currentNoteId: "",
          }),
        );
      }
    } else {
      const newVisitId = generateId();
      const newVisit = {
        _id: newVisitId,
        visitName: "New Encounter",
        visitCreatedDate: formatDateTime(new Date()),
        visitLanguage: "ENGLISH",
        visitAudioTime: 0,
        visitTranscript: "",
        visitAdditionalPatientContext: "",
        visitFinished: false,
        visitTypeId: "",
        visitTypeUptoDate: true,
        visitCopyMetric: 0,
        visitDiagnosesState: "INCOMPLETE",
        notes: [],
        diagnoses: [],
      };
      dispatch(addVisit(newVisit));
      dispatch(
        setCurrentState({
          currentVisitId: newVisitId,
          currentTab: "RECORD",
          currentNoteId: "",
        }),
      );
    }
    dispatch(deleteVisit(visitId));
  };

  return (
    <div className="visititemlist-visititemlist">
      {Object.keys(visitsByDate).map((date) => (
        <React.Fragment key={date}>
          <span className="sidebar-recentlabel td3">{date.toUpperCase()}</span>
          {visitsByDate[date].map(renderVisitItem)}
        </React.Fragment>
      ))}
    </div>
  );
};

VisitItemList.defaultProps = {
  className: "",
};

VisitItemList.propTypes = {
  className: PropTypes.string,
};

export default VisitItemList;
