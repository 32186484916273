/**
 * External Dependencies
 */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { quantum } from "ldrs";
quantum.register();

/**
 * Internal Dependencies
 * - Styles
 * - Redux Actions
 */
import "./recordheader.css";
import { useSelector, useDispatch } from "react-redux";
import { setVisit } from "../../../redux/actions";

/**
 * RecordHeader component for displaying and editing the visit title.
 * @returns {JSX.Element} The RecordHeader component.
 */
const RecordHeader = () => {
  /**
   * @dispatch - Redux dispatch function to send actions to the store.
   * @currentVisitId - Current visit ID from the global state.
   * @currentVisit - Current visit data from the global state.
   * @isEditing - State for the editing status.
   * @setIsEditing - Function to update the editing status.
   * @title - State for the visit title.
   * @setTitle - Function to update the visit title.
   * @inputRef - Reference to the input DOM element.
   */
  const dispatch = useDispatch();
  const currentVisitId = useSelector(
    (state) => state.globalData.currentState.currentVisitId,
  );
  const currentVisit = useSelector((state) =>
    state.globalData.userData.visits.find(
      (visit) => visit._id === currentVisitId,
    ),
  );

  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState(currentVisit?.visitName);
  const inputRef = useRef(null);

  useEffect(() => {
    setTitle(currentVisit?.visitName);
  }, [currentVisit?.visitName]);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.select();
    }
  }, [isEditing]);

  /**
   * Handles the title click to enable editing.
   */
  const handleTitleClick = () => {
    setIsEditing(true);
  };

  /**
   * Handles changes to the title input.
   * @param {object} e - The change event.
   */
  const handleTitleChange = (e) => {
    setTitle(e.target.value);
    dispatch(setVisit(currentVisitId, { visitName: e.target.value }));
  };

  /**
   * Handles key press events in the title input.
   * @param {object} e - The key press event.
   */
  const handleTitleKeyPress = (e) => {
    if (e.key === "Enter") {
      setIsEditing(false);
      dispatch(setVisit(currentVisitId, { visitName: title }));
    }
  };

  return (
    <div className="recordheader-recordheader">
      <l-quantum size="45" speed="1.75" color="#212a2fff"></l-quantum>
      {isEditing ? (
        <input
          type="text"
          value={title}
          onChange={handleTitleChange}
          onKeyPress={handleTitleKeyPress}
          onBlur={() => setIsEditing(false)}
          autoFocus
          ref={inputRef}
          className="input3 recordheader-center-input"
        />
      ) : (
        <div className="recordheader-visitname" onClick={handleTitleClick}>
          <span className="recordheader-name tl4">{title}</span>
          <img
            alt="pencil"
            src="/icons/pencil1.svg"
            className="recordheader-image img4"
          />
        </div>
      )}
    </div>
  );
};

RecordHeader.defaultProps = {
  name: "New Encounter",
};

RecordHeader.propTypes = {
  name: PropTypes.string,
};

export default RecordHeader;
