/**
 * External Dependencies
 */
import { combineReducers } from "redux";

/**
 * Internal Dependencies
 * - Reducers
 */
import globalReducer from "./globalReducer";

/**
 * Combines all the reducers into a single root reducer.
 */
const rootReducer = combineReducers({
  globalData: globalReducer,
});

export default rootReducer;
