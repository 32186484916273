/**
 * External Dependencies
 */
import React from "react";
import PropTypes from "prop-types";

/**
 * Internal Dependencies
 * - Styles
 */
import "./selectedtab.css";

/**
 * Selectedtab component for displaying a selected tab.
 * @param {object} props - The component props.
 * @param {string} props.rootClassName - The root class name for custom styling.
 * @param {string} props.image - The image source for the tab icon.
 * @param {string} props.text - The text for the tab.
 * @returns {JSX.Element} The Selectedtab component.
 */
const Selectedtab = ({ rootClassName, image, text }) => {
  return (
    <div className={`selectedtab-selectedtab ${rootClassName}`}>
      <img alt="image" src={image} className="selectedtab-image img1" />
      <span className="td2">{text}</span>
    </div>
  );
};

Selectedtab.defaultProps = {
  rootClassName: "",
  text: "Notes",
  image: "/icons/notes.svg",
};

Selectedtab.propTypes = {
  rootClassName: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.string,
};

export default Selectedtab;
