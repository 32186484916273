/**
 * External Dependencies
 */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

/**
 * Internal Components
 */
import Dropdown from "../../shared/dropdown/dropdown";
import SubButton from "../../shared/subbutton";

/**
 * Internal Dependencies
 * - Styles
 * - Redux Actions
 */
import "./languagetypecontainer.css";
import { useSelector, useDispatch } from "react-redux";
import { setVisit } from "../../../redux/actions";

/**
 * LanguageTypeContainer component for selecting and setting the language type for the visit.
 * @param {object} props - The component props.
 * @param {string} props.text - The text to display.
 * @returns {JSX.Element} The LanguageTypeContainer component.
 */
const LanguageTypeContainer = ({ text }) => {
  /**
   * @dispatch - Redux dispatch function to send actions to the store.
   * @currentVisitId - Current visit ID from the global state.
   * @visits - Visits from the global state.
   * @currentVisit - Current visit based on the current visit ID.
   * @showDropdown - State for showing the dropdown.
   * @setShowDropdown - Function to update the show dropdown state.
   * @dropdownRef - Reference to the dropdown DOM element.
   */
  const dispatch = useDispatch();
  const currentVisitId = useSelector(
    (state) => state.globalData.currentState.currentVisitId,
  );
  const visits = useSelector((state) => state.globalData.userData.visits);
  const currentVisit = visits.find((visit) => visit._id === currentVisitId);

  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const languageOptions = [
    { id: "ENGLISH", name: "English" },
    { id: "ENGLISH2", name: "English (enhanced)" },
    { id: "SPANISH", name: "Spanish" },
    { id: "CHINESE", name: "Chinese" },
    { id: "HINDI", name: "Hindi" },
    { id: "ARABIC", name: "Arabic" },
    { id: "FRENCH", name: "French" },
  ];

  useEffect(() => {
    /**
     * Handles click outside event to close the dropdown.
     * @param {object} event - The click event.
     */
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  /**
   * Handles the selection change event for the language.
   * @param {string} newLanguage - The new language ID.
   */
  const handleLanguageChange = (newLanguage) => {
    dispatch(setVisit(currentVisitId, { visitLanguage: newLanguage }));
    setShowDropdown(false);
  };

  /**
   * Toggles the visibility of the dropdown.
   */
  const toggleDropdown = () => {
    if (currentVisit.visitAudioTime === 0) {
      setShowDropdown(!showDropdown);
    }
  };

  const selectedLanguage = currentVisit?.visitLanguage ?? "ENGLISH";

  return (
    <div className="languagetypecontainer-languagetypecontainer">
      <span className="languagetypecontainer-text tl1 type-text">{text}</span>
      <span className="spacer"></span>
      <div>
        <SubButton
          image="/icons/down.svg"
          text={
            languageOptions.find((lang) => lang.id === selectedLanguage)
              ?.name || "English"
          }
          onClick={toggleDropdown}
          textFirst={true}
          rootClassName={
            currentVisit.visitAudioTime !== 0 ? "disabled-button" : ""
          }
        />

        {showDropdown && (
          <div
            className="zero languagetypecontainer-dropdown"
            ref={dropdownRef}
          >
            <Dropdown
              buttonList={[]}
              sectionList={[
                {
                  name: "LANGUAGES",
                  labelImage: "/icons/flag.svg",
                  options: languageOptions.map((lang) => ({
                    text: lang.name,
                    image: "/icons/checkmark.svg",
                    onClick: () => handleLanguageChange(lang.id),
                    selected: lang.id === selectedLanguage,
                  })),
                },
              ]}
              showBorder={false}
            />
          </div>
        )}
      </div>
    </div>
  );
};

LanguageTypeContainer.defaultProps = {
  text: "Language",
};

LanguageTypeContainer.propTypes = {
  text: PropTypes.string,
};

export default LanguageTypeContainer;
