/**
 * External Dependencies
 */
import React from "react";
import PropTypes from "prop-types";

/**
 * Internal Dependencies
 * - Styles
 */
import "./selectedvisititem.css";

/**
 * SelectedVisitItem component for displaying a selected visit item with a delete option.
 * @param {object} props - The component props.
 * @param {string} props.name - The name of the visit.
 * @param {string} props.date - The date of the visit.
 * @param {function} props.onTrashClick - The function to handle the trash icon click.
 * @returns {JSX.Element} The SelectedVisitItem component.
 */
const SelectedVisitItem = ({ name, date, onTrashClick }) => {
  return (
    <div className="selectedvisititem-selectedvisititem">
      <div className="selectedvisititem-visitheader">
        <span className="singlelinewrap td2">{name}</span>
        <span className="selectedvisititem-date td2 singlelinewrap">
          {date}
        </span>
      </div>
      <img
        onClick={onTrashClick}
        alt="image"
        src="/icons/trash.svg"
        className="selectedvisititem-trash img2"
      />
    </div>
  );
};

SelectedVisitItem.defaultProps = {
  name: "Keshav's follow up visit",
  date: "6:40 PM • 47 min",
  onTrashClick: () => {},
};

SelectedVisitItem.propTypes = {
  name: PropTypes.string,
  date: PropTypes.string,
  onTrashClick: PropTypes.func,
};

export default SelectedVisitItem;
