/**
 * External Dependencies
 */
import { createStore } from "redux";

/**
 * Internal Dependencies
 * - Reducers
 */
import rootReducer from "./reducer";

/**
 * Creates the Redux store with the root reducer.
 */
const store = createStore(rootReducer);

export default store;
