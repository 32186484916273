/**
 * External Dependencies
 */
import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

/**
 * Internal Dependencies
 * - Styles
 */
import "./texteditor.css";
import { replacementDictionary } from "../../scripts/replacementDictionary";

/**
 * TextEditor Component
 * @param {string} placeholder - Placeholder text for the textarea.
 * @param {string} value - Current value of the textarea.
 * @param {function} onChange - Function to handle change events.
 * @returns {JSX.Element} TextEditor component
 */
const TextEditor = ({ placeholder, value, onChange }) => {
  const textareaRef = useRef(null);
  const overlayRef = useRef(null);

  /**
   * Handle change in textarea value
   * @param {object} e - Change event
   */
  const handleChange = (e) => {
    onChange(e.target.value);
  };

  /**
   * Synchronize scroll position of overlay with textarea
   */
  const handleScroll = () => {
    if (overlayRef.current && textareaRef.current) {
      overlayRef.current.scrollTop = textareaRef.current.scrollTop;
      overlayRef.current.scrollLeft = textareaRef.current.scrollLeft;
    }
  };

  /**
   * Handle resize event to maintain scroll synchronization
   */
  const handleResize = () => {
    if (overlayRef.current && textareaRef.current) {
      overlayRef.current.scrollTop = textareaRef.current.scrollTop;
      overlayRef.current.scrollLeft = textareaRef.current.scrollLeft;
    }
  };

  /**
   * Highlight specific parts of the input text
   * @param {string} inputText - Text to be highlighted
   * @returns {JSX.Element[]} Array of highlighted parts
   */
  const highlightText = (inputText) => {
    const parts = inputText.split(/(@[^@]+@|\{[^{}]+\}|\[[^\]]+\]|\*\*\*)/g);

    return parts.map((part, index) => {
      const partLowerCase = part.toLowerCase();

      // Check if the part exists in the replacement dictionary
      if (replacementDictionary[partLowerCase]) {
        return (
          <span key={index} className="highlight-red">
            {part}
          </span>
        );
      } else if (part.match(/^@[\s\S]+@$/)) {
        return (
          <span key={index} className="highlight-blue">
            {part}
          </span>
        );
      } else if (part.match(/^\{[\s\S]+\}$/) || part.match(/^\[[\s\S]+\]$/)) {
        return (
          <span key={index} className="highlight-green">
            {part}
          </span>
        );
      } else if (part === "***") {
        return (
          <span key={index} className="highlight-green">
            {part}
          </span>
        );
      }
      return part;
    });
  };

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.addEventListener("scroll", handleScroll);
      window.addEventListener("resize", handleResize);

      return () => {
        textarea.removeEventListener("scroll", handleScroll);
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);

  return (
    <div className="texteditor-textarea-wrapper td1">
      <textarea
        ref={textareaRef}
        value={value}
        onChange={handleChange}
        className="texteditor-textarea td1"
        placeholder={placeholder}
      />
      <div ref={overlayRef} className="texteditor-textarea-overlay td1">
        {highlightText(value)}
        {value.endsWith("\n") && <br />}{" "}
        {/* Add line break if textarea ends with newline */}
      </div>
    </div>
  );
};

TextEditor.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TextEditor;
