/**
 * External Dependencies
 */
import React from "react";
import PropTypes from "prop-types";

/**
 * Internal Dependencies
 * - Styles
 */
import "./circleiconlight.css";

/**
 * CircleIconLight Component
 * @param {string} rootClassName - Additional class names for the root element.
 * @param {string} imageSrc - Source URL for the icon image.
 * @returns {JSX.Element} CircleIconLight component
 */
const CircleIconLight = ({ rootClassName, imageSrc }) => {
  return (
    <div className={`circleiconlight-circleiconlight ${rootClassName} `}>
      <img alt="image" src={imageSrc} className="img3" />
    </div>
  );
};

CircleIconLight.defaultProps = {
  imageSrc: "https://play.teleporthq.io/static/svg/default-img.svg",
  rootClassName: "",
};

CircleIconLight.propTypes = {
  imageSrc: PropTypes.string,
  rootClassName: PropTypes.string,
};

export default CircleIconLight;
