/**
 * External Dependencies
 */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

/**
 * Internal Components
 */
import Activetoggle from "./activetoggle";
import Inactivetoggle from "./inactivetoggle";

/**
 * Internal Dependencies
 * - Styles
 */
import "./togglecontainer.css";
import { setVisitType } from "../../../redux/actions";

/**
 * Togglecontainer Component
 * @param {object} props - Component props
 * @returns {JSX.Element} Togglecontainer component
 */
const Togglecontainer = ({ currentVisitTypeId }) => {
  /**
   * @dispatch - Redux dispatch function to send actions to the store.
   * @currentVisitType - Current visit type based on the current visit type ID.
   * @visitTypeBodyType - State for the visit type body type.
   * @setVisitTypeBodyType - Function to update the visit type body type.
   */
  const dispatch = useDispatch();
  const currentVisitType = useSelector((state) =>
    state.globalData.userData.visitTypes.find(
      (visitType) => visitType._id === currentVisitTypeId,
    ),
  );

  const [visitTypeBodyType, setVisitTypeBodyType] = useState(
    currentVisitType?.visitTypeBodyType || "EXAMPLE",
  );

  useEffect(() => {
    if (currentVisitType) {
      setVisitTypeBodyType(currentVisitType.visitTypeBodyType || "EXAMPLE");
    }
  }, [currentVisitType]);

  /**
   * Handles the toggle click
   * @param {string} toggleType - The type of toggle clicked
   */
  const handleToggleClick = (toggleType) => {
    setVisitTypeBodyType(toggleType);
    dispatch(
      setVisitType(currentVisitTypeId, { visitTypeBodyType: toggleType }),
    );
  };

  return (
    <div className="togglecontainer-togglecontainer">
      <div className="togglecontainer-toggle">
        {visitTypeBodyType === "EXAMPLE" ? (
          <Activetoggle text="Example" image="/icons/rocket1.svg" />
        ) : (
          <Inactivetoggle
            text="Example"
            onClick={() => handleToggleClick("EXAMPLE")}
          />
        )}
        {visitTypeBodyType === "TEMPLATE" ? (
          <Activetoggle text="Epic SmartPhrase" image="/icons/rocket1.svg" />
        ) : (
          <Inactivetoggle
            text="Epic SmartPhrase"
            onClick={() => handleToggleClick("TEMPLATE")}
          />
        )}
      </div>
    </div>
  );
};

Togglecontainer.propTypes = {
  currentVisitTypeId: PropTypes.string.isRequired,
};

export default Togglecontainer;
