/**
 * External Dependencies
 */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

/**
 * Internal Components
 */
import Button from "../../shared/button";
import DarkButton from "../../shared/darkbutton";
import Togglecontainer from "./togglecontainer";
import TextEditor from "../../shared/texteditor";
/**
 * Internal Dependencies
 * - Styles
 * - Redux Actions
 * - Services and Utilities
 */
import "./frametemplates.css";
import {
  setVisitType,
  setCurrentState,
  setNotification,
} from "../../../redux/actions";
import {
  generateVisitTypeInstructions,
  updateVisitTypeUptoDateState,
} from "../../../scripts/generateVisitType";
import { replacementDictionary } from "../../../scripts/replacementDictionary.js";
import { cardio } from "ldrs";
import { generateId } from "../../../scripts/apiService";
cardio.register();

/**
 * FrameTemplates Component
 * @param {string} textareaPlaceholderBody - Placeholder text for the body textarea.
 * @returns {JSX.Element} FrameTemplates component
 */
const FrameTemplates = ({ textareaPlaceholderBody }) => {
  /**
   * @dispatch - Redux dispatch function to send actions to the store.
   * @userVisits - User visits from global data
   * @currentVisitTypeId - Current visit type ID from the global state.
   * @currentVisitType - Current visit type based on the current visit type ID.
   * @name - State for the visit type name.
   * @setName - Function to update the visit type name.
   * @body - State for the visit type body.
   * @setBody - Function to update the visit type body.
   */
  const dispatch = useDispatch();
  const userVisits = useSelector((state) => state.globalData.userData.visits);
  const currentVisitTypeId = useSelector(
    (state) => state.globalData.currentState.currentVisitTypeId,
  );

  const currentVisitType = useSelector((state) =>
    state.globalData.userData.visitTypes.find(
      (visitType) => visitType._id === currentVisitTypeId,
    ),
  );

  const [name, setName] = useState(currentVisitType?.visitTypeName || "");
  const [body, setBody] = useState(currentVisitType?.visitTypeBody || "");

  /**
   * Updates the name and body state when the current visit type changes.
   */
  useEffect(() => {
    if (currentVisitType) {
      setName(currentVisitType.visitTypeName);
      setBody(currentVisitType.visitTypeBody);
    }
  }, [currentVisitType]);

  /**
   * Handles the polish button click event
   */
  /**
   * Handles the polish button click event
   */
  const handlePolishClick = () => {
    let polishedBody = body;

    Object.keys(replacementDictionary).forEach((key) => {
      const regex = new RegExp(key, "gi");
      polishedBody = polishedBody.replace(regex, replacementDictionary[key]);
    });

    setBody(polishedBody);
  };

  /**
   * Handles the save button click event.
   */
  const handleSaveClick = async () => {
    try {
      const invalidSmartLinks = Object.keys(replacementDictionary);

      const bodyLowerCase = body.toLowerCase();
      const hasInvalidSmartLinks = invalidSmartLinks.some((link) =>
        bodyLowerCase.includes(link.toLowerCase()),
      );

      if (hasInvalidSmartLinks) {
        dispatch(
          setNotification({
            name: "Invalid SmartLinks",
            description:
              'Please remove and replace all SmartLinks highlighted in red with AI instructions, or click "Polish."',
            status: "warning",
            duration: 5000,
            isClosable: true,
          }),
        );
        return;
      }

      if (currentVisitType.visitTypeBodyType === "EXAMPLE") {
        dispatch(
          setNotification({
            name: "Building Template",
            description: "Estimated 10-20 seconds",
            status: "info",
            duration: 5000,
            isClosable: true,
          }),
        );
        dispatch(
          setVisitType(currentVisitTypeId, { visitTypeState: "CREATING" }),
        );
        generateVisitTypeInstructions(
          currentVisitTypeId,
          body,
          dispatch,
          userVisits,
        );
      } else {
        const instructions = `
  <template>
  ${body}
  </template>

  <rule_set>
  1. Replace Bracketed Content:
    - For text in [] or {}, choose one of the provided options or infer based on context if no suitable option is available.
    - Do not replace curly brackets with @.
    - Examples:
      "{is/is not}" → Choose "is" or "is not"
      "{BARIATRICOPERATIONS:74507}" → Infer a bariatric operation, e.g., "gastric bypass"
      "[Patient's current weight]" → Infer a reasonable weight, e.g., "185 lbs"

  2. Replace Triple Asterisks:
    - Substitute *** with inferred content that makes sense in the context.
    - Examples:
      "The patient's weight today is *** lbs" → "The patient's weight today is 180 lbs"
      "status-post {BARIATRICOPERATIONS:74507} from ***." → "status-post gastric sleeve from 3 months ago."

  3. Default Options and Assumptions:
    - Use the default option if provided.
    - If no default is given, make a reasonable assumption.
    - Examples:
      "{improved/unchanged/worsened:default=unchanged}" → "unchanged"
      "[Patient's age]" → Infer a reasonable age, e.g., "45 years old"

  4. Preserve @ Delimiters:
    - Do not modify any text between @ symbols.
    - Examples:
      "@NAME@", "@CAPHE@", "@DBLINK(EPT,110,,,)@", "@BMI@", "@TD@ at @NOW@" should remain unchanged.

  5. Preserve Free Text:
    - Do not modify existing text outside special markers ([], {}, ***, @).
    - You may add new content to free text sections when appropriate, following the document's style and structure.

  6. Remove Brackets and Asterisks:
    - In the final output, remove all [], {}, and ***.
    - Keep all @ symbols and their content unchanged.

  7. Scope of Modifications:
    - Only modify text within brackets or triple asterisks.
    - Remove all [], {}, and ***, and do not substitute these with @ symbol. 
    - Do not alter other parts of the template.
    - Do not remove or modify any text between @ symbols.
  </rule_set>`;

        dispatch(
          setNotification({
            name: "Template Created",
            description: "You can now use your template with patient visits",
            status: "success",
            duration: 5000,
            isClosable: true,
          }),
        );
        dispatch(
          setVisitType(currentVisitTypeId, {
            visitTypeBody: body,
            visitTypeInstructions: instructions,
            visitTypeState: "UPTO_DATE",
            noteTypes: [
              {
                _id: generateId(),
                noteTypeName: currentVisitType.visitTypeName,
                noteTypeInstructions: instructions,
              },
            ],
          }),
        );
      }
      updateVisitTypeUptoDateState(currentVisitTypeId, dispatch, userVisits);
      dispatch(
        setCurrentState({ currentVisitTypeId: "", currentTab: "TCENTER" }),
      );
    } catch (error) {
      console.error("Error generating instructions:", error);
    }
  };

  /**
   * Handles the name input change event.
   * @param {object} e - The input change event.
   */
  const handleNameChange = (e) => {
    setName(e.target.value);
    autoSaveVisitType({ visitTypeName: e.target.value });
  };

  /**
   * Handles the body input change event.
   * @param {object} e - The input change event.
   */
  const handleBodyChange = (newBody) => {
    const newBodyValue =
      currentVisitType.visitTypeBodyType === "TEMPLATE"
        ? newBody
        : newBody.target.value;
    setBody(newBodyValue);
    autoSaveVisitType({
      visitTypeBody: newBodyValue,
      visitTypeState: newBodyValue === "" ? "INCOMPLETE" : "NOT_UPTO_DATE",
    });
  };

  /**
   * Autosave the visit type details.
   * @param {object} updatedFields - The updated fields to save.
   */
  const autoSaveVisitType = (updatedFields) => {
    dispatch(setVisitType(currentVisitTypeId, updatedFields));
  };

  /**
   * Handles the cancel button click event.
   */
  const handleCancelClick = () => {
    dispatch(setCurrentState({ currentTab: "TCENTER" }));
  };

  /**
   * Checks if the body contains any of the placeholders from the replacementDictionary
   * @param {string} text - The text to check for placeholders.
   * @returns {boolean} True if any placeholder is found, otherwise false.
   */
  const containsPlaceholders = (text) => {
    return Object.keys(replacementDictionary).some((key) =>
      text.toLowerCase().includes(`${key.toLowerCase()}`),
    );
  };

  return (
    <div className="frametemplates-frametemplates">
      <div className="frametemplates-textarea-container">
        <Togglecontainer currentVisitTypeId={currentVisitTypeId} />
        <span className="tl3">TEMPLATE NAME</span>
        <input
          type="text"
          placeholder="Untitled Template"
          className="frametemplates-input td1"
          value={name}
          onChange={handleNameChange}
        />
        <span className="tl3">
          {currentVisitType?.visitTypeBodyType === "TEMPLATE"
            ? "SMARTPHRASE TEMPLATE"
            : "EXAMPLE NOTE"}
        </span>
        {currentVisitType?.visitTypeBodyType === "TEMPLATE" ? (
          <TextEditor
            placeholder={`Paste the contents of your Epic SmartPhrase here. We'll recognize any smartlinks and have them formatted for an easy copy & paste into your patient note.\n\nRequires HALO Intelligence.`}
            value={body}
            onChange={handleBodyChange}
          />
        ) : (
          <textarea
            placeholder={textareaPlaceholderBody}
            className="frametemplates-textarea td1"
            value={body}
            onChange={handleBodyChange}
          />
        )}
      </div>
      <div className="frametemplates-button-container">
        {containsPlaceholders(body) ? (
          <Button
            rootClassName="button-root-class-name5"
            text="Polish"
            image="/icons/redo.svg"
            onClick={handlePolishClick}
          />
        ) : (
          <Button
            rootClassName="button-root-class-name5"
            text="Back"
            image="/icons/back.svg"
            onClick={handleCancelClick}
          />
        )}
        <DarkButton
          rootClassName="button-root-class-name6"
          text="Build"
          image="/icons/rocket1.svg"
          onClick={handleSaveClick}
        />
      </div>
    </div>
  );
};

FrameTemplates.defaultProps = {
  textareaPlaceholderBody:
    "Enter your sample note here and Halo will personalize itself to your style of documentation.",
};

FrameTemplates.propTypes = {
  textareaPlaceholderBody: PropTypes.string,
};

export default FrameTemplates;
