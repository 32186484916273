/**
 * External Dependencies
 */
import React from "react";
import PropTypes from "prop-types";

/**
 * Internal Dependencies
 * - Styles
 */
import "./recordingbutton.css";

/**
 * RecordingButton component for rendering a button with an image and text.
 * @param {object} props - The component props.
 * @param {function} props.onClick - The function to handle click events.
 * @param {string} props.text - The button text.
 * @returns {JSX.Element} The RecordingButton component.
 */
const RecordingButton = ({ onClick, text }) => {
  return (
    <div onClick={onClick} className="recordingbutton-recordingbutton">
      <img alt="image" src="/icons/recording.svg" className="img1" />
      <span className="recordingbutton-text td2">{text}</span>
    </div>
  );
};

RecordingButton.defaultProps = {
  text: "00:00",
  onClick: () => {},
};

RecordingButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
};

export default RecordingButton;
