/**
 * External Dependencies
 */
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";

/**
 * Internal Components
 */
import DarkButton from "../components/shared/darkbutton";
import Footer from "../components/shared/footer";

/**
 * Internal Dependencies
 * - Styles
 * - Redux Actions
 * - Services and Utilities
 */
import "./login.css";
import { setCurrentState, setNotification } from "../redux/actions";
import { apiLoginUser } from "../scripts/apiService";

/**
 * Login component for handling user login functionality.
 * @returns {JSX.Element} Login component
 */
const Login = () => {
  /**
   * @dispatch - Dispatch function for Redux actions
   * @email - State to track email input value
   * @setEmail - Function to update email state
   * @password - State to track password input value
   * @setPassword - Function to update password state
   */
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  /**
   * Validates the email format.
   * @param {string} email - The email to validate.
   * @returns {boolean} - True if the email format is valid, false otherwise.
   */
  const validateEmail = (email) => /\S+@\S+\.\S+/.test(email);

  /**
   * Handles the login process.
   */
  const handleLogin = async () => {
    if (!email || !password) {
      return dispatch(
        setNotification({
          name: "Incomplete Fields",
          description: "Please enter both the email and password.",
          status: "error",
          duration: 5000,
          isClosable: true,
        }),
      );
    }
    if (!validateEmail(email)) {
      return dispatch(
        setNotification({
          name: "Invalid Field",
          description: "Please enter a valid email.",
          status: "error",
          duration: 5000,
          isClosable: true,
        }),
      );
    }

    const { success, sessionId, isRecording } = await apiLoginUser(
      email,
      password,
    );
    if (success) {
      dispatch(setCurrentState({ currentSessionId: sessionId }));
      sessionStorage.setItem("currentSessionId", sessionId);
      window.location.href = "/dashboard";
    } else {
      if (isRecording) {
        dispatch(
          setNotification({
            name: "Recording in progress",
            description:
              "A user is currently recording. Please wait until they finish.",
            status: "error",
            duration: 5000,
            isClosable: true,
          }),
        );
      } else {
        dispatch(
          setNotification({
            name: "Login Error",
            description: "Incorrect email or password. Try again!",
            status: "error",
            duration: 5000,
            isClosable: true,
          }),
        );
      }
    }
  };

  /**
   * Handles the Enter key press event on the password input.
   * @param {object} e - The event object.
   */
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <div className="login-container">
      <Helmet>
        <title>Login - Halo</title>
        <meta
          name="description"
          content="Halo's AI medical scribe enhances physician experience by eliminating patient charting drudgery, allowing doctors to focus on compassionate patient care."
        />
        <meta property="og:title" content="Login - Halo" />
        <meta
          property="og:description"
          content="Halo's AI medical scribe enhances physician experience by eliminating patient charting drudgery, allowing doctors to focus on compassionate patient care."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/6c79c93a-b196-4a81-890c-58d62a680e8d/aff5fbcd-b493-49c9-91ba-3aaa09ff6ab6?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <div className="spacer"></div>
      <div className="login-container1">
        <span className="login-text td4">Login to Halo</span>
        <span className="login-text1 tl1">
          <span className="halosubheader1">
            Please review our{" "}
            <a
              href="https://docs.google.com/document/d/11AmfCnLkPrLX_PlD4uybt9uh6RXjqm3TGydwwEQXV-8/edit?usp=sharing"
              target="_blank"
              rel="noopener noreferrer"
            >
              <u>Terms and Conditions</u>
            </a>
            .
          </span>
        </span>
        <span className="td2">Email</span>
        <div className="login-emailcontainer">
          <input
            type="email"
            placeholder="Enter your email"
            id="emailinput"
            className="login-input input2"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoCapitalize="none"
          />
          <img
            src="/icons/user.svg"
            alt="user icon"
            className="login-image img1"
          />
        </div>
        <span className="td2">Password</span>
        <div className="login-passwordcontainer">
          <input
            type="password"
            placeholder="Enter your password"
            id="passwordinput"
            className="login-input1 input2"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={handleKeyPress}
            autoCapitalize="none"
          />
          <img
            src="/icons/password.svg"
            alt="password icon"
            className="login-image1 img1"
          />
        </div>
        <DarkButton
          rootClassName="darkbutton-root-class-name"
          image="/icons/rocket1.svg"
          onClick={handleLogin}
        />
      </div>
      <div className="spacer"></div>
      <Footer />
    </div>
  );
};

export default Login;
