/**
 * External Dependencies
 */
import React from "react";
import { useDispatch } from "react-redux";

/**
 * Internal Components
 */
import Iconlabel from "../../shared/iconlabel";

/**
 * Internal Dependencies
 * - Styles
 */
import "./sidebarexternallinks.css";
import { setCurrentState } from "../../../redux/actions";

/**
 * SidebarExternalLinks component for rendering external links in the sidebar.
 * @returns {JSX.Element} The SidebarExternalLinks component.
 */
const SidebarExternalLinks = () => {
  const dispatch = useDispatch();

  /**
   * Handles template center button click event.
   */
  const handleTemplateCenterClicked = () => {
    dispatch(
      setCurrentState({
        currentVisitId: "",
        currentNoteId: "",
        currentTab: "TCENTER",
        currentDisplaySidebar: false,
      }),
    );
  };

  /**
   * Handles the logout click event.
   */
  const handleLogoutClick = async () => {
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = "/login";
  };

  /**
   * Handles the contact us click event.
   */
  const handleContactUsClick = () => {
    window.location.href = "mailto:usehalohealth@gmail.com";
  };

  /**
   * Handles the request a feature click event.
   */
  const handleRequestFeatureClick = () => {
    window.open("https://forms.gle/Tmtgas4coR82QbXd8", "_blank");
  };

  return (
    <div className="sidebarexternallinks-sidebarexternallinks">
      {window.innerWidth <= 740 && (
        <Iconlabel
          text="Template Center"
          image="/icons/rocket.svg"
          onClick={handleTemplateCenterClicked}
        ></Iconlabel>
      )}
      <Iconlabel
        text="Request a feature"
        image="/icons/outbound.svg"
        onClick={handleRequestFeatureClick}
      ></Iconlabel>
      <Iconlabel
        text="Contact us"
        image="/icons/question.svg"
        onClick={handleContactUsClick}
      ></Iconlabel>
      <Iconlabel
        text="Logout"
        image="/icons/logout.svg"
        onClick={handleLogoutClick}
      ></Iconlabel>
    </div>
  );
};

export default SidebarExternalLinks;
