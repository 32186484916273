/**
 * External Dependencies
 */
import React from "react";
import PropTypes from "prop-types";

/**
 * Internal Dependencies
 * - Styles
 */
import "./darkbutton.css";

/**
 * DarkButton Component
 * @param {function} onClick - Click handler function for the button.
 * @param {string} rootClassName - Additional class names for the root element.
 * @param {string} image - Source URL for the button image.
 * @param {string} text - Text to display on the button.
 * @returns {JSX.Element} DarkButton component
 */
const DarkButton = ({ onClick, rootClassName, image, text }) => {
  return (
    <div
      onClick={onClick}
      className={`darkbutton-darkbutton ${rootClassName} `}
    >
      <img src={image} alt="image" className="img1" />
      <span className="darkbutton-text td2">{text}</span>
    </div>
  );
};

DarkButton.defaultProps = {
  image: "/icons/in1.svg",
  text: "Log in",
  rootClassName: "",
  onClick: () => {},
};

DarkButton.propTypes = {
  image: PropTypes.string,
  text: PropTypes.string,
  rootClassName: PropTypes.string,
  onClick: PropTypes.func,
};

export default DarkButton;
