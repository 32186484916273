/**
 * External Dependencies
 */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

/**
 * Internal Components
 */
import Dropdown from "../../shared/dropdown/dropdown";
import SubButton from "../../shared/subbutton";

/**
 * Internal Dependencies
 * - Styles
 * - Redux Actions
 */
import "./notetypecontainer.css";
import { useSelector, useDispatch } from "react-redux";
import {
  setCurrentState,
  setVisit,
  setNotification,
} from "../../../redux/actions";

/**
 * NoteTypeContainer component for selecting and setting the visit structure.
 * @param {object} props - The component props.
 * @param {string} props.text - The text to display.
 * @returns {JSX.Element} The NoteTypeContainer component.
 */
const NoteTypeContainer = ({ text }) => {
  /**
   * @dispatch - Redux dispatch function to send actions to the store.
   * @currentVisitId - Current visit ID from the global state.
   * @userdata - Current user data from global state
   * @visits - Visits from the global state.
   * @visitTypes - Visit types from the global state.
   * @currentVisit - Current visit based on the current visit ID.
   * @selectedVisitTypeId - State for the selected visit type ID.
   * @setSelectedVisitTypeId - Function to update the selected visit type ID.
   * @showDropdown - State for showing the dropdown.
   * @setShowDropdown - Function to update the show dropdown state.
   * @dropdownRef - Reference to the dropdown DOM element.
   */
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.globalData.userData);
  const currentVisitId = useSelector(
    (state) => state.globalData.currentState.currentVisitId,
  );
  const visits = useSelector((state) => state.globalData.userData.visits);
  const visitTypes = useSelector(
    (state) => state.globalData.userData.visitTypes,
  );

  const currentVisit = visits.find((visit) => visit._id === currentVisitId);
  const [selectedVisitTypeId, setSelectedVisitTypeId] = useState(
    currentVisit.visitTypeId || "",
  );
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    /**
     * Updates the selected visit type ID when the current visit or visit types change.
     */
    if (
      !visitTypes.find((visitType) => visitType._id === selectedVisitTypeId)
    ) {
      dispatch(setVisit(currentVisitId, { visitTypeId: "" }));
    } else if (currentVisit && currentVisit.visitTypeId !== "") {
      setSelectedVisitTypeId(currentVisit.visitTypeId);
    }
  }, [currentVisit.visitTypeId, visitTypes]);

  useEffect(() => {
    /**
     * Handles click outside event to close the dropdown.
     * @param {object} event - The click event.
     */
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  /**
   * Handles the selection change event for the visit type.
   * @param {object} newVisitTypeId - The new visit type ID.
   */
  const handleSelectChange = (newVisitTypeId) => {
    if (newVisitTypeId === "new-visit-type") {
      dispatch(
        setCurrentState({
          currentVisitId: "",
          currentNoteId: "",
          currentTab: "TCENTER",
        }),
      );
      return;
    }
    setSelectedVisitTypeId(newVisitTypeId);
    dispatch(setVisit(currentVisitId, { visitTypeId: newVisitTypeId }));
    setShowDropdown(false);
  };

  /**
   * Toggles the visibility of the dropdown.
   */
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  /**
   * Truncates the visit type name to 12 characters and appends "..." if longer.
   * @param {string} name - The visit type name.
   * @returns {string} - The truncated visit type name.
   */
  const truncateVisitTypeName = (name) => {
    return name.length > 14 ? `${name.substring(0, 14)}...` : name;
  };

  /**
   * Handles the creation of a new visit type.
   */
  const handleNewVisitTypeClick = () => {
    if (!userData.userIsRecording) {
      dispatch(
        setCurrentState({
          currentVisitId: "",
          currentNoteId: "",
          currentTab: "TCENTER",
        }),
      );
      setShowDropdown(false);
    } else {
      dispatch(
        setNotification({
          name: "Recording in Progress",
          description:
            "You cannot leave this screen until you finish a recording.",
          status: "warning",
          duration: 5000,
          isClosable: true,
        }),
      );
    }
  };

  return (
    <div className="notetypecontainer-notetypecontainer">
      <span className="notetypecontainer-text tl1 type-text">{text}</span>
      <span className="spacer"></span>
      <div>
        <SubButton
          image="/icons/down.svg"
          text={truncateVisitTypeName(
            visitTypes.find(
              (visitType) => visitType._id === selectedVisitTypeId,
            )?.visitTypeName || "Templates",
          )}
          onClick={toggleDropdown}
          textFirst={true}
        ></SubButton>

        {showDropdown && (
          <div className="zero notetypecontainer-dropdown" ref={dropdownRef}>
            <Dropdown
              buttonList={[]}
              sectionList={[
                {
                  name: "TEMPLATES",
                  labelImage: "/icons/plus.svg",
                  labelAction: handleNewVisitTypeClick,
                  options: visitTypes
                    .filter(
                      (visitType) =>
                        visitType.visitTypeState !== "CREATING" &&
                        visitType.visitTypeState !== "ERROR",
                    )
                    .sort((a, b) => {
                      if (
                        a.visitTypeState === "DEFAULT" &&
                        b.visitTypeState !== "DEFAULT"
                      )
                        return 1;
                      if (
                        a.visitTypeState !== "DEFAULT" &&
                        b.visitTypeState === "DEFAULT"
                      )
                        return -1;
                      return 0;
                    })
                    .map((visitType) => ({
                      text:
                        visitType.visitTypeState === "DEFAULT"
                          ? `${visitType.visitTypeName} (HALO)`
                          : visitType.visitTypeName,
                      image: "/icons/checkmark.svg",
                      onClick: () => handleSelectChange(visitType._id),
                      selected: visitType._id === selectedVisitTypeId,
                    })),
                },
              ]}
              showBorder={false}
            />
          </div>
        )}
      </div>
    </div>
  );
};

NoteTypeContainer.defaultProps = {
  text: "Set template",
};

NoteTypeContainer.propTypes = {
  text: PropTypes.string,
};

export default NoteTypeContainer;
