/**
 * External Dependencies
 */
import React from "react";
import PropTypes from "prop-types";

/**
 * Internal Dependencies
 * - Styles
 */
import "./dropdownoption.css";

/**
 * DropdownOption Component
 * @param {string} text - Text to display for the option.
 * @param {string} image - Source URL for the option image.
 * @param {function} onClick - Click handler function for the option.
 * @param {boolean} selected - Whether the option is selected.
 * @returns {JSX.Element} DropdownOption component
 */
const DropdownOption = ({ text, image, onClick, selected }) => {
  return (
    <div className="dropdownoption-dropdownoption" onClick={onClick}>
      <span className="dropdownoption-text singlelinewrap td2">{text}</span>
      {selected && (
        <img src={image} alt="selected" className="dropdownoption-image img1" />
      )}
    </div>
  );
};

DropdownOption.propTypes = {
  text: PropTypes.string,
  image: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
};

export default DropdownOption;
