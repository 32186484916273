/**
 * External Dependencies
 */
import React from "react";
import PropTypes from "prop-types";

/**
 * Internal Dependencies
 * - Styles
 */
import "./finishbutton.css";

/**
 * FinishButton component for rendering a button with an image and text.
 * @param {object} props - The component props.
 * @param {string} props.text - The button text.
 * @param {string} props.image - The button image source.
 * @param {function} props.onClick - The function to handle click events.
 * @returns {JSX.Element} The FinishButton component.
 */
const FinishButton = ({ text, onClick, image }) => {
  return (
    <div className="finishbutton-finishbutton" onClick={onClick}>
      <img alt="image" src={image} className="img1" />
      <span className="finishbutton-text td2">{text}</span>
    </div>
  );
};

FinishButton.defaultProps = {
  text: "Finish",
  image: "/icons/rocket1.svg",
  onClick: () => {},
};

FinishButton.propTypes = {
  text: PropTypes.string,
  image: PropTypes.string,
  onClick: PropTypes.func,
};

export default FinishButton;
