/**
 * External Dependencies
 */
import React from "react";
import PropTypes from "prop-types";

/**
 * Internal Components
 */
import { Tooltip } from "primereact/tooltip";

/**
 * Internal Dependencies
 * - Styles
 */
import "./unselectedtab.css";

/**
 * UnselectedTab component for displaying an unselected tab with a tooltip for "Coming soon!" items.
 * @param {object} props - The component props.
 * @param {string} props.image - The image source for the tab icon.
 * @param {function} props.onClick - The function to handle the tab click.
 * @param {string} props.text - The text for the tab.
 * @returns {JSX.Element} The UnselectedTab component.
 */
const UnselectedTab = ({ image, onClick, text }) => {
  return (
    <>
      {text === "Outbound" ? (
        <>
          <div
            className="unselectedtab-unselectedtab-disabled"
            onClick={onClick}
            data-pr-tooltip="Coming soon!"
            data-pr-position="bottom"
          >
            <img alt="image" src={image} className="unselectedtab-image img1" />
            <span className="td2">{text}</span>
          </div>
          <Tooltip
            target=".unselectedtab-unselectedtab-disabled"
            className="custom-tooltip"
          />
        </>
      ) : (
        <div className="unselectedtab-unselectedtab" onClick={onClick}>
          <img alt="image" src={image} className="unselectedtab-image img1" />
          <span className="td2">{text}</span>
        </div>
      )}
    </>
  );
};

UnselectedTab.defaultProps = {
  rootClassName: "",
  image: "/icons/notes.svg",
  text: "Notes",
  onClick: () => {},
};

UnselectedTab.propTypes = {
  rootClassName: PropTypes.string,
  image: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
};

export default UnselectedTab;
