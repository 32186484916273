/**
 * External Dependencies
 */
import React from "react";
import PropTypes from "prop-types";

/**
 * Internal Components
 */
import CircleIconLight from "../../shared/circleiconlight";
import SubButton2 from "../../shared/subbutton2";

/**
 * Internal Dependencies
 * - Styles
 */
import "./templateitem.css";

/**
 * TemplateItem Component
 * @param {string} name - The name of the template.
 * @param {string} numberOfVisits - The number of visits associated with the template.
 * @param {string} visitTypeState - The state of the visit type.
 * @param {function} onClick - The click handler for editing the template.
 * @param {function} onDelete - The click handler for deleting the template.
 * @returns {JSX.Element} TemplateItem component
 */
const TemplateItem = ({
  name,
  numberOfVisits,
  visitTypeState,
  bodyType,
  onClick,
  onDelete,
}) => {
  /**
   * Handles the delete button click event and stops propagation.
   * @param {object} event - The click event.
   */
  const handleDeleteClick = (event) => {
    event.stopPropagation();
    onDelete();
  };

  /**
   * Handles the edit button click event and stops propagation.
   * @param {object} event - The click event.
   */
  const handleEditClick = (event) => {
    event.stopPropagation();
    onClick();
  };

  // Determine styles based on visitTypeState
  const getStateStyles = () => {
    switch (visitTypeState) {
      case "CREATING":
        return {
          borderColor: "#074765",
          backgroundColor: "#e5f6fd",
          color: "#074765",
        };
      case "NOT_UPTO_DATE":
        return {
          borderColor: "#724b14",
          backgroundColor: "#fff4e5",
          color: "#724b14",
        };
      case "UPTO_DATE":
        return {
          borderColor: "#3e6241",
          backgroundColor: "#edf7ed",
          color: "#3e6241",
        };
      case "INCOMPLETE":
        return {
          borderColor: "#787878",
          backgroundColor: "rgba(179, 179, 179, 0.1)",
          color: "#787878",
        };
      case "DEFAULT":
        return {
          borderColor: "#697ec7",
          backgroundColor: "#ebeefa",
          color: "#697ec7",
          pointerEvents: "none",
          cursor: "not-allowed",
        };
      case "ERROR":
        return {
          borderColor: "#662b2a",
          backgroundColor: "#fdeded",
          color: "#662b2a",
        };
      default:
        return {};
    }
  };

  const stateStyles = getStateStyles();

  return (
    <div
      className={`templateitem-templateitem ${
        visitTypeState === "DEFAULT" ? "templateitem-templateitem-default" : ""
      }`}
      onClick={
        visitTypeState !== "CREATING" && visitTypeState !== "DEFAULT"
          ? onClick
          : null
      }
      style={{
        pointerEvents: stateStyles.pointerEvents,
        cursor: stateStyles.cursor,
      }}
    >
      <CircleIconLight
        rootClassName="circleiconlight-root-class-name1"
        imageSrc="/icons/notes.svg"
      />
      <div className="templateitem-textcontainer">
        <span className="singlelinewrap td2">{name}</span>
        <span className="templateitem-text1 singlelinewrap tl1">
          {bodyType === "EXAMPLE"
            ? "Example Note"
            : bodyType === ""
              ? "Provided by HALO"
              : "Epic Smartphrase"}
        </span>
      </div>
      <div className="templateitem-visitusecontainer">
        <img
          src="/icons/notes.svg"
          className="templateitem-image img1"
          alt="Icon"
        />
        <span className="templateitem-text2 td2">{numberOfVisits} visits</span>
      </div>
      <div className="templateitem-statecontainer"></div>
      <div className="templateitem-buttoncontainer">
        <div
          className="templateitem-statelabel"
          style={{
            backgroundColor: stateStyles.backgroundColor,
            color: stateStyles.color,
            borderColor: stateStyles.borderColor,
          }}
        >
          <span className="templateitem-text3">
            {visitTypeState === "CREATING"
              ? "Creating"
              : visitTypeState === "NOT_UPTO_DATE"
                ? "Not updated"
                : visitTypeState === "INCOMPLETE"
                  ? "Incomplete"
                  : visitTypeState === "DEFAULT"
                    ? "Default"
                    : visitTypeState === "ERROR"
                      ? "Error"
                      : "Up to date"}
          </span>
        </div>
        {window.innerWidth > 740 && (
          <SubButton2
            image="/icons/pencil1.svg"
            onClick={
              visitTypeState !== "CREATING" && visitTypeState !== "DEFAULT"
                ? handleEditClick
                : null
            }
            rootClassName={
              visitTypeState === "DEFAULT" || visitTypeState === "CREATING"
                ? "templateitem-notvisible-button"
                : ""
            }
          />
        )}

        <SubButton2
          onClick={visitTypeState !== "DEFAULT" ? handleDeleteClick : null}
          rootClassName={
            visitTypeState === "DEFAULT" ? "templateitem-notvisible-button" : ""
          }
        />
      </div>
    </div>
  );
};

TemplateItem.defaultProps = {
  name: "Follow-up",
  numberOfVisits: "5",
  visitTypeState: "",
  bodyType: "",
  onClick: () => {},
  onDelete: () => {},
};

TemplateItem.propTypes = {
  name: PropTypes.string,
  numberOfVisits: PropTypes.string,
  visitTypeState: PropTypes.string,
  bodyType: PropTypes.string,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
};

export default TemplateItem;
