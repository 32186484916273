/**
 * External Dependencies
 */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * Internal Components
 */
import CircleIconDark from "../../shared/circleicondark";

/**
 * Internal Dependencies
 * - Styles
 * - PrimeReact Components
 * - Redux Actions
 * - Services and Utilities
 */
import "./unselectednote.css";
import { Tooltip } from "primereact/tooltip";
import { Skeleton } from "primereact/skeleton";
import { useSelector, useDispatch } from "react-redux";
import { generateNote } from "../../../scripts/generateNotes";
import { setNote, setNotification, setVisit } from "../../../redux/actions";

/**
 * UnselectedNote component for displaying a note that is not currently selected.
 * @param {object} props - The component props.
 * @param {string} props.id - The ID of the note.
 * @param {string} props.title - The title of the note.
 * @param {string} props.body - The body of the note.
 * @param {function} props.onClick - The function to handle the click event.
 * @param {boolean} props.noteIsVisible - The visibility status of the note.
 * @returns {JSX.Element} The UnselectedNote component.
 */
const UnselectedNote = ({ id, title, body, onClick, noteIsVisible }) => {
  /**
   * @dispatch - Redux dispatch function to send actions to the store.
   * @currentVisitId - Current visit ID from the global state.
   * @userData - User data from the global state.
   * @currentVisit - Current visit based on the current visit ID.
   * @note - Current note based on the note ID.
   * @isBodyVisible - State for the body visibility status.
   * @setIsBodyVisible - Function to update the body visibility status.
   * @viewIcon - State for the view icon source.
   * @setViewIcon - Function to update the view icon source.
   * @copyImageSrc - State for the copy icon source.
   * @setCopyImageSrc - Function to update the copy icon source.
   * @isLoading - State for the loading status.
   * @setIsLoading - Function to update the loading status.
   * @noteBody - State for the note body.
   * @setNoteBody - Function to update the note body.
   */
  const dispatch = useDispatch();
  const currentVisitId = useSelector(
    (state) => state.globalData.currentState.currentVisitId,
  );
  const userData = useSelector((state) => state.globalData.userData);
  const currentVisit = userData.visits.find(
    (visit) => visit._id === currentVisitId,
  );
  const note = currentVisit.notes.find((note) => note._id === id);

  const [isBodyVisible, setIsBodyVisible] = useState(noteIsVisible);
  const [viewIcon, setViewIcon] = useState(
    noteIsVisible ? "/icons/view.svg" : "/icons/hide.svg",
  );
  const [copyImageSrc, setCopyImageSrc] = useState("/icons/copy.svg");
  const [isLoading, setIsLoading] = useState(false);
  const [noteBody, setNoteBody] = useState(body);

  const formatBoldText = (text) => {
    const regex = /\*\*(.*?)\*\*/g;
    const parts = text.split(regex);
    return parts.map((part, index) =>
      index % 2 === 1 ? <strong key={index}>{part}</strong> : part,
    );
  };

  useEffect(() => {
    /**
     * Updates the note body when the note changes.
     */
    if (note && note.noteBody) {
      setNoteBody(note.noteBody);
    }
  }, [note]);

  useEffect(() => {
    /**
     * Generates the note for the first time if it is empty.
     */
    const generateFirstTimeNote = async () => {
      if (note.noteBody === "" && note.noteFirstTimeGenerated) {
        setIsLoading(true);
        try {
          const visitType = userData.visitTypes.find(
            (type) => type._id === currentVisit.visitTypeId,
          );
          const noteType = visitType.noteTypes.find(
            (type) => type._id === note.noteTypeId,
          );

          await generateNote(
            currentVisitId,
            id,
            currentVisit.visitTranscript,
            currentVisit.visitAdditionalPatientContext,
            visitType.visitTypeBodyType,
            noteType.noteTypeInstructions,
            body,
            "generate for the first time",
            "SONNET",
            dispatch,
            setIsLoading,
          );
        } catch (error) {
          console.error("Error creating thread or generating note:", error);
          dispatch(
            setNotification({
              name: "ERROR",
              description: "Something went wrong. Try again!",
              status: "error",
              duration: 5000,
              isClosable: true,
            }),
          );
        } finally {
          setIsLoading(false);
        }
      }
    };
    generateFirstTimeNote();
  }, []);

  /**
   * Toggles the visibility of the note body.
   */
  const toggleBodyVisibility = () => {
    dispatch(setNote(currentVisitId, id, { noteIsVisible: !isBodyVisible }));
    setIsBodyVisible(!isBodyVisible);
    setViewIcon(isBodyVisible ? "/icons/hide.svg" : "/icons/view.svg");
  };

  /**
   * Handles the copy icon click to copy the note body to the clipboard.
   */
  const handleCopyClick = async () => {
    dispatch(
      setVisit(currentVisitId, {
        visitCopyMetric: (currentVisit.visitCopyMetric || 0) + 0.001,
      }),
    );

    try {
      await navigator.clipboard.writeText(
        `${title.toUpperCase()}:\n${noteBody}`,
      );
      setCopyImageSrc("/icons/checkmark.svg");
      setTimeout(() => {
        setCopyImageSrc("/icons/copy.svg");
      }, 2000);
    } catch (error) {
      console.error("Error copying to clipboard:", error);
    }
  };

  return (
    <div className="unselectednote-unselectednote" onClick={onClick}>
      <CircleIconDark
        imageSrc="/icons/pencil.svg"
        rootClassName="circleicondark-root-class-name2"
      ></CircleIconDark>
      <div className="unselectednote-notecontainer card1">
        <div className="unselectednote-noteheader">
          <span className="td3">{title.toUpperCase()}</span>
          <img
            alt="copy"
            src={copyImageSrc}
            className="unselectednote-image img2 copy-icon"
            onClick={(e) => {
              e.stopPropagation();
              handleCopyClick();
            }}
            data-pr-tooltip="Copy"
            data-pr-position="top"
          />
          <Tooltip target=".copy-icon" className="custom-tooltip" />
          <img
            alt="view"
            src={viewIcon}
            className="unselectednote-image1 img2 view-icon"
            onClick={(e) => {
              e.stopPropagation();
              toggleBodyVisibility();
            }}
            data-pr-tooltip="View"
            data-pr-position="top"
          />
          <Tooltip target=".view-icon" className="custom-tooltip" />
        </div>

        {isLoading ? (
          <div className="horizontal-container">
            <div className="row">
              <Skeleton width="80%" className="mb-2" />
              <Skeleton width="20%" className="mb-2" />
            </div>
            <div className="row">
              <Skeleton width="30%" className="mb-2" />
              <Skeleton width="30%" className="mb-2" />
              <Skeleton width="40%" className="mb-2" />
            </div>
            <div className="row">
              <Skeleton width="40%" className="mb-2" />
              <Skeleton width="60%" className="mb-2" />
            </div>
          </div>
        ) : (
          <>
            {isBodyVisible && (
              <span className="unselectednote-body td1">
                {formatBoldText(noteBody)}
              </span>
            )}
          </>
        )}
      </div>
    </div>
  );
};

UnselectedNote.defaultProps = {
  id: "",
  body: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  title: "SUBJECTIVE",
  onClick: () => {},
  noteIsVisible: true,
};

UnselectedNote.propTypes = {
  id: PropTypes.string,
  body: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
  noteIsVisible: PropTypes.bool,
};

export default UnselectedNote;
