/**
 * External Dependencies
 */
import React, { useState } from "react";
import PropTypes from "prop-types";

/**
 * Internal Components
 */
import PromptItem from "./prompitem";
import AskAiInput from "./askaiinput";

/**
 * Internal Dependencies
 * - Styles
 * - Redux Actions
 */
import "./aiprompt.css";
import { useSelector, useDispatch } from "react-redux";
import { setNote } from "../../../redux/actions";

/**
 * AiPrompt component for displaying AI prompt options and handling user input.
 * @param {object} props - The component props.
 * @param {string} props.rootClassName - The root class name for styling.
 * @param {function} props.onSubmit - The function to handle the submit action.
 * @returns {JSX.Element} The AiPrompt component.
 */
const AiPrompt = ({ rootClassName, onSubmit }) => {
  /**
   * @dispatch - Redux dispatch function to send actions to the store.
   * @currentVisitId - Current visit ID from the global state.
   * @currentNoteId - Current note ID from the global state.
   * @note - Current note from the global state based on the current visit and note ID.
   * @inputValue - Input value state to track the text input value.
   * @setInputValue - Function to update input value state.
   */
  const dispatch = useDispatch();
  const currentVisitId = useSelector(
    (state) => state.globalData.currentState.currentVisitId,
  );
  const currentNoteId = useSelector(
    (state) => state.globalData.currentState.currentNoteId,
  );
  const note = useSelector((state) =>
    state.globalData.userData.visits
      .find((visit) => visit._id === currentVisitId)
      ?.notes.find((note) => note._id === currentNoteId),
  );

  const [inputValue, setInputValue] = useState("");

  /**
   * Handles prompt item click event.
   * @param {string} text - The text of the clicked prompt item.
   */
  const handlePromptItemClick = (text) => {
    setInputValue(text);
  };

  /**
   * Handles input change event.
   * @param {object} event - The change event.
   */
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  /**
   * Handles submit button click event.
   */
  const handleOnSubmitClick = () => {
    onSubmit(inputValue);
    setInputValue("");
    dispatch(
      setNote(currentVisitId, currentNoteId, { ...note, noteBody: inputValue }),
    );
  };

  return (
    <div className={`aiprompt-aiprompt ${rootClassName}`}>
      <div className="aiprompt-promptitemlist">
        <PromptItem
          rootClassName="prompitem-root-class-name"
          text="Brief"
          onClick={() => handlePromptItemClick("Brief")}
        />
        <PromptItem
          rootClassName="prompitem-root-class-name2"
          text="Detailed"
          onClick={() => handlePromptItemClick("Detailed")}
        />
      </div>
      <AskAiInput
        rootClassName="askaiinput-root-class-name"
        value={inputValue}
        onChange={handleInputChange}
        onSubmit={handleOnSubmitClick}
        placeholder="Ask AI to change..."
      />
    </div>
  );
};

AiPrompt.defaultProps = {
  rootClassName: "",
  onSubmit: () => {},
};

AiPrompt.propTypes = {
  rootClassName: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default AiPrompt;
