/**
 * External Dependencies
 */
import axios from "axios";
import CryptoJS from "crypto-js";
import { v4 as uuidv4 } from "uuid";
import zlib from "pako";
import { Buffer } from "buffer";
/**
 * Internal Dependencies
 * - Environment Variables
 */
const API_URL = process.env.REACT_APP_API_URL;
const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY;

/**
 * Generates a unique identifier using UUID v4.
 * @returns {string} The generated UUID.
 */
const generateId = () => uuidv4();

/**
 * Encrypts data using AES encryption.
 * @param {object} data - The data to encrypt.
 * @returns {string} The encrypted data in Base64 format.
 */
const encryptData = (data) => {
  const key = CryptoJS.enc.Hex.parse(ENCRYPTION_KEY);
  const iv = CryptoJS.lib.WordArray.random(16);
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  const encryptedBase64 = CryptoJS.enc.Base64.stringify(
    iv.concat(encrypted.ciphertext),
  );
  return encryptedBase64;
};

/**
 * Decrypts data encrypted with AES encryption.
 * @param {string} encryptedData - The encrypted data in Base64 format.
 * @returns {object} The decrypted data.
 */
const decryptData = (encryptedData) => {
  const key = CryptoJS.enc.Hex.parse(ENCRYPTION_KEY);
  const encryptedDataBytes = CryptoJS.enc.Base64.parse(encryptedData);
  const iv = CryptoJS.lib.WordArray.create(
    encryptedDataBytes.words.slice(0, 4),
  );
  const ciphertext = CryptoJS.lib.WordArray.create(
    encryptedDataBytes.words.slice(4),
  );
  const decrypted = CryptoJS.AES.decrypt({ ciphertext }, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
};

/**
 * Builds an encrypted query string from parameters.
 * @param {object} params - The parameters to encrypt.
 * @returns {string} The encrypted query string.
 */
const buildEncryptedQueryString = (params) =>
  Object.keys(params)
    .map(
      (key) =>
        `${encodeURIComponent("encrypted" + key.charAt(0).toUpperCase() + key.slice(1))}=${encodeURIComponent(encryptData(params[key]))}`,
    )
    .join("&");

/**
 * API call to log in a user.
 * @param {string} userEmail - The user's email.
 * @param {string} userPassword - The user's password.
 * @returns {Promise<object>} The login response.
 */
const apiLoginUser = async (userEmail, userPassword) => {
  const queryParams = buildEncryptedQueryString({ userEmail, userPassword });
  try {
    const response = await axios.post(`${API_URL}user/login?${queryParams}`);
    const encryptedData = response.data.data;
    if (response.data.success) {
      const decryptedData = decryptData(encryptedData);
      const sessionId = decryptedData.sessionId;
      return { success: true, sessionId, isRecording: false };
    } else {
      return {
        success: false,
        sessionId: "",
        isRecording: response.data.message === "RECORDING_IN_PROGRESS",
      };
    }
  } catch (error) {
    console.error("Error during login:", error);
    return { success: false };
  }
};

/**
 * API call to register a new user.
 * @param {string} userName - The user's name.
 * @param {string} userEmail - The user's email.
 * @param {string} userPassword - The user's password.
 * @returns {Promise<object>} The registration response.
 */
const apiRegisterUser = async (userName, userEmail, userPassword) => {
  const queryParams = buildEncryptedQueryString({
    userName,
    userEmail,
    userPassword,
  });
  try {
    const response = await axios.post(`${API_URL}user/register?${queryParams}`);
    if (response.data.success) {
      const encryptedData = response.data.data;
      const decryptedData = decryptData(encryptedData);
      const sessionId = decryptedData.sessionId;
      return { success: true, sessionId };
    } else {
      return { success: false };
    }
  } catch (error) {
    console.error("Error during registration:", error);
    return { success: false };
  }
};

/**
 * API call to fetch user data.
 * @param {string} sessionId - The user's session ID.
 * @returns {Promise<object>} The user data response.
 */
const apiGetUserData = async (sessionId) => {
  const queryParams = buildEncryptedQueryString({ sessionId });
  try {
    const response = await fetch(`${API_URL}user/get?${queryParams}`, {
      method: "GET",
    });
    const data = await response.json();
    if (data.success) {
      const encryptedData = data.data;
      const decryptedData = decryptData(encryptedData);
      return { success: true, data: decryptedData.userData };
    } else {
      return { success: false };
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
    return { success: false };
  }
};

/**
 * API call to register a new user.
 * @param {string} sessionId - The user's session id.
 * @param {string} userData - The user's data.
 * @returns {Promise<object>} The registration response.
 */
const apiSendUserDataFinal = async (sessionId, userData) => {
  const encryptedSessionId = encryptData(sessionId);
  const compressedData = compressData(userData);
  try {
    await axios.post(`${API_URL}user/final`, {
      encryptedSessionId,
      data: compressedData,
    });
    return { success: true };
  } catch (error) {
    console.error("Error during final data submission:", error);
    return { success: false };
  }
};

/**
 * API call to ask the assistant for a response.
 * @param {string} instructions - The instructions for the assistant.
 * @param {string} message - The message for the assistant.
 * @param {string} modelType - The model type for the assistant.
 * @param {function} onMessage - Callback function for handling incoming messages.
 * @param {function} onError - Callback function for handling errors.
 */
const apiAskAssistantStream = (
  instructions,
  message,
  modelType,
  onMessage,
  onError,
) => {
  const socket = new WebSocket(
    `${API_URL.replace(/^https?/, "ws")}assistant/ask`,
  );

  socket.onopen = async () => {
    try {
      const requestData = {
        instructions: encryptData(instructions),
        message: encryptData(message),
        modelType: encryptData(modelType),
      };
      socket.send(JSON.stringify(requestData));
    } catch (error) {
      console.error("Error during WebSocket connection:", error);
    }
  };

  socket.onmessage = async (event) => {
    try {
      onMessage(event.data);
    } catch (error) {
      console.error("Error handling WebSocket message:", error);
    }
  };

  socket.onerror = (error) => {
    console.error("WebSocket error:", error);
    onError(error);
  };

  socket.onclose = (event) => {
    if (event.wasClean) {
      console.log(
        `WebSocket connection closed cleanly, code=${event.code}, reason=${event.reason}`,
      );
    } else {
      console.error("WebSocket connection died");
    }
  };
};

/**
 *
 * @param {*} data - uncompressed data
 * @returns compressed data
 */
const compressData = (data) => {
  const jsonString = JSON.stringify(data);
  const compressed = zlib.deflate(jsonString);
  const base64Compressed = Buffer.from(compressed).toString("base64");
  return base64Compressed;
};

/**
 * API call to set user data using WebSocket.
 * @param {WebSocket} socket - The WebSocket instance.
 * @param {string} sessionId - The user's session ID.
 * @param {object} userData - The user data to set.
 */
const apiSendUserDataViaWebSocket = (socket, sessionId, userData) => {
  if (socket && socket.current.readyState === WebSocket.OPEN) {
    const compressedData = compressData(userData);
    const requestData = {
      encryptedSessionId: encryptData(sessionId),
      data: compressedData,
    };
    socket.current.send(JSON.stringify(requestData));
  }
};

/**
 * Sets up WebSocket connection
 * @param {function} handleWebSocketMessage - Function to handle WebSocket messages
 * @param {function} handleWebSocketError - Function to handle WebSocket errors
 * @returns {object} WebSocket reference
 */
const apiSetupWebSocket = (handleWebSocketMessage, handleWebSocketError) => {
  const socket = { current: null };
  let reconnectInterval = 250; // Initial reconnect interval (5 seconds)

  const connect = () => {
    socket.current = new WebSocket(
      `${API_URL.replace(/^https?/, "ws")}user/set`,
    );

    socket.current.onopen = () => {
      console.info(`WebSocket connection established`);
    };

    socket.current.onmessage = (event) => {
      const response = JSON.parse(event.data);
      handleWebSocketMessage(response);
    };

    socket.current.onerror = (error) => {
      console.error("WebSocket error:", error);
      handleWebSocketError(error);
      attemptReconnect();
    };

    socket.current.onclose = (event) => {
      if (event.wasClean) {
        console.warn(
          `WebSocket connection closed cleanly, code=${event.code}, reason=${event.reason}`,
        );
      } else {
        console.error(
          `WebSocket connection died, code=${event.code}, reason=${event.reason}`,
        );
        attemptReconnect();
      }
    };
  };

  const attemptReconnect = () => {
    setTimeout(() => {
      console.warn(`Attempting to reconnect to WebSocket`);
      connect();
    }, reconnectInterval);
  };

  connect();
  return socket;
};

/**
 * API call to get AssemblyAI API token.
 * @returns {Promise<object>} The token response.
 */
const apiGetAssemblyApiKey = async () => {
  try {
    const response = await axios.post(`${API_URL}assistant/assemblyApiKey`);
    if (response.data.success) {
      const encryptedData = response.data.data.token;
      const decryptedData = decryptData(encryptedData);
      const token = decryptedData.token;
      return { success: true, token };
    } else {
      return { success: false };
    }
  } catch (error) {
    console.error("Error fetching AssemblyAI API token:", error);
    return { success: false };
  }
};

export {
  generateId,
  apiLoginUser,
  apiRegisterUser,
  apiGetUserData,
  apiAskAssistantStream,
  apiSendUserDataViaWebSocket,
  apiSendUserDataFinal,
  apiSetupWebSocket,
  apiGetAssemblyApiKey,
};
