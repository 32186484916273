import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import "./diagnosesitem.css";
import CircleIconLight from "../../shared/circleiconlight";
import { setDiagnoses } from "../../../redux/actions";

/**
 * DiagnosesItem component for displaying a single diagnosis item with copy-to-clipboard functionality.
 * @param {object} props - Component props.
 * @param {string} props.id - The unique ID of the diagnosis.
 * @param {string} props.identifier - The identifier for the diagnosis.
 * @param {string} props.name - The name of the diagnosis.
 * @param {string} props.description - The description of the diagnosis.
 * @param {string} [props.rootClassName] - Additional root class names.
 * @returns {JSX.Element} DiagnosesItem component.
 */
const DiagnosesItem = ({
  id,
  identifier,
  name,
  description,
  rootClassName,
}) => {
  /**
   * @dispatch - Redux dispatch function to send actions to the store.
   * @userData - User data from the global state.
   * @currentVisitId - Current visit ID from the global state.
   * @currentVisit - Current visit based on the current visit ID.
   * @diagnosis - Diagnosis of the current visit.
   */
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.globalData.userData);
  const currentVisitId = useSelector(
    (state) => state.globalData.currentState.currentVisitId,
  );
  const currentVisit =
    userData.visits?.find((visit) => visit._id === currentVisitId) || null;
  const diagnosis = currentVisit?.diagnoses?.find(
    (diagnosis) => diagnosis._id === id,
  );

  useEffect(() => {
    /**
     * Handles render of initial diagnosis.
     */
    if (diagnosis && diagnosis.diagnosesSelected === undefined) {
      dispatch(setDiagnoses(currentVisitId, id, { diagnosesSelected: true }));
    }
  }, [diagnosis, dispatch, currentVisitId, id]);

  /**
   * Handle click event to select/deselect diagnosis.
   */
  const handleClick = () => {
    dispatch(
      setDiagnoses(currentVisitId, id, {
        diagnosesSelected: !diagnosis.diagnosesSelected,
      }),
    );
  };

  return (
    <div
      className={`diagnosesitem-diagnosesitem ${rootClassName}`}
      onClick={handleClick}
    >
      <CircleIconLight
        imageSrc={`${
          diagnosis?.diagnosesSelected ? "/icons/check.svg" : "/icons/empty.svg"
        }`}
        rootClassName={`circleiconlight-root-class-name ${
          diagnosis?.diagnosesSelected ? "" : "circleiconlight-unselected"
        }`}
      />
      <div
        className={`${
          diagnosis?.diagnosesSelected
            ? "diagnosesitem-diagnosescontainer-selected"
            : "diagnosesitem-diagnosescontainer"
        } card1`}
      >
        <span className="td3 diagnosesitem-text info-codename">
          {identifier}
        </span>
        <span className="td2 diagnosesitem-text info-description">{name}</span>
        <span className="diagnosesitem-body diagnosesitem-text td2 info-details">
          {description}
        </span>
      </div>
    </div>
  );
};

DiagnosesItem.propTypes = {
  id: PropTypes.string.isRequired,
  identifier: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  rootClassName: PropTypes.string,
};

DiagnosesItem.defaultProps = {
  rootClassName: "",
};

export default DiagnosesItem;
