/**
 * External Dependencies
 */
import React from "react";
import PropTypes from "prop-types";

/**
 * Internal Dependencies
 * - Styles
 * - Internal Components
 */
import "./dropdown.css";
import DropdownButton from "./dropdownbutton";
import DropdownSection from "./dropdownsection";

/**
 * Dropdown Component
 * @param {Array} buttonList - List of buttons to display at the top of the dropdown.
 * @param {Array} sectionList - List of sections to display in the dropdown.
 * @param {boolean} showBorder - Whether to show a border between sections.
 * @returns {JSX.Element} Dropdown component
 */
const Dropdown = ({ buttonList, sectionList, showBorder }) => {
  return (
    <div className="dropdown-dropdown">
      {buttonList.map((button, index) => (
        <DropdownButton
          key={index}
          image={button.image}
          text={button.text}
          onClick={button.onClick}
        />
      ))}
      {sectionList.length > 0 &&
        sectionList.map((section, index) => (
          <DropdownSection
            key={index}
            label={section.name}
            labelImage={section.labelImage}
            labelAction={section.labelAction}
            options={section.options}
            showBorder={showBorder}
          />
        ))}
    </div>
  );
};

Dropdown.propTypes = {
  buttonList: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      text: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  sectionList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      labelImage: PropTypes.string,
      labelAction: PropTypes.func,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          image: PropTypes.string,
          onClick: PropTypes.func,
          selected: PropTypes.bool,
        }),
      ),
    }),
  ).isRequired,
  showBorder: PropTypes.bool,
};

export default Dropdown;
