/**
 * External Dependencies
 */
import React from "react";
import PropTypes from "prop-types";

/**
 * Internal Dependencies
 * - Styles
 */
import "./inactivetoggle.css";

/**
 * Inactivetoggle Component
 * @param {object} props - Component props
 * @param {string} props.text - Text to display
 * @param {function} props.onClick - Click handler
 * @returns {JSX.Element} Inactivetoggle component
 */
const Inactivetoggle = ({ text, onClick }) => {
  return (
    <div className="inactivetoggle-inactivetoggle" onClick={onClick}>
      <span className="inactivetoggle-text td2">{text}</span>
    </div>
  );
};

Inactivetoggle.defaultProps = {
  text: "CPT",
};

Inactivetoggle.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default Inactivetoggle;
