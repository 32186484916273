/**
 * External Dependencies
 */
import React from "react";
import PropTypes from "prop-types";

/**
 * Internal Dependencies
 * - Styles
 */
import "./dropdownbutton.css";

/**
 * DropdownButton Component
 * @param {string} image - Source URL for the button image.
 * @param {string} text - Text to display on the button.
 * @param {function} onClick - Click handler function for the button.
 * @returns {JSX.Element} DropdownButton component
 */
const DropdownButton = ({ image, text, onClick }) => {
  return (
    <div className="dropdownbutton-dropdownbutton" onClick={onClick}>
      <img src={image} alt="image" className="dropdownbutton-image img1" />
      <span className="td2">{text}</span>
    </div>
  );
};

DropdownButton.propTypes = {
  image: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
};

export default DropdownButton;
