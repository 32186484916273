/**
 * External Dependencies
 */
import React from "react";
import PropTypes from "prop-types";

/**
 * Internal Dependencies
 * - Styles
 */
import "./circleicondark.css";

/**
 * CircleIconDark Component
 * @param {string} rootClassName - Additional class names for the root element.
 * @param {function} onClick - Click handler function for the icon.
 * @param {string} imageSrc - Source URL for the icon image.
 * @returns {JSX.Element} CircleIconDark component
 */
const CircleIconDark = ({ rootClassName, onClick, imageSrc }) => {
  return (
    <div
      onClick={onClick}
      className={`circleicondark-circleicondark ${rootClassName} `}
    >
      <img alt="image" src={imageSrc} className="img3" />
    </div>
  );
};

CircleIconDark.defaultProps = {
  rootClassName: "",
  imageSrc: "https://play.teleporthq.io/static/svg/default-img.svg",
  onClick: () => {},
};

CircleIconDark.propTypes = {
  rootClassName: PropTypes.string,
  imageSrc: PropTypes.string,
  onClick: PropTypes.func,
};

export default CircleIconDark;
