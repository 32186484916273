/**
 * External Dependencies
 */
import React from "react";
import PropTypes from "prop-types";

/**
 * Internal Dependencies
 * - Styles
 */
import "./button.css";

/**
 * Button Component
 * @param {string} rootClassName - Additional class names for the root element.
 * @param {function} onClick - Click handler function for the button.
 * @param {string} image - Source URL for the button image.
 * @param {string} text - Text to display on the button.
 * @returns {JSX.Element} Button component
 */
const Button = ({ rootClassName, onClick, image, text }) => {
  return (
    <div onClick={onClick} className={`button-button ${rootClassName} `}>
      {image && <img alt="image" src={image} className="img1" />}
      <span className="td2">{text}</span>
    </div>
  );
};

Button.defaultProps = {
  image: "",
  rootClassName: "",
  text: "Text",
  onClick: () => {},
};

Button.propTypes = {
  image: PropTypes.string,
  rootClassName: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
};

export default Button;
