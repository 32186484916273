/**
 * External Dependencies
 */
import React from "react";
import PropTypes from "prop-types";

/**
 * Internal Dependencies
 * - Styles
 */
import "./iconlabel.css";

/**
 * IconLabel Component
 * @param {string} image - Source URL for the icon image.
 * @param {string} text - Text to display next to the icon.
 * @param {function} onClick - Click handler function for the icon label.
 * @returns {JSX.Element} IconLabel component
 */
const IconLabel = ({ image, text, onClick }) => {
  return (
    <div className="iconlabel-iconlabel" onClick={onClick}>
      <img alt="image" src={image} className="iconlabel-image img1" />
      <span className="td2">{text}</span>
    </div>
  );
};

IconLabel.defaultProps = {
  image: "/icons/notes.svg",
  text: "Notes",
  onClick: () => {},
};

IconLabel.propTypes = {
  image: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
};

export default IconLabel;
