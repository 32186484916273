/**
 * External Dependencies
 */
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

/**
 * Internal Components
 */
import Button from "../../shared/button";
import PausedButton from "./pausedbutton";
import RecordingButton from "./recordingbutton";
import FinishButton from "./finishbutton";
import DarkButton from "../../shared/darkbutton";

/**
 * Internal Dependencies
 * - Styles
 * - Custom Hooks
 * - Redux Actions
 */
import "./recordingcontainer.css";
import { audioTranscribe } from "../../../scripts/audioTranscribe";
import {
  setVisit,
  setCurrentState,
  setNotification,
  setRecordingState,
} from "../../../redux/actions";
import { generateId } from "../../../scripts/apiService";

/**
 * RecordingContainer component for managing the recording process.
 * @returns {JSX.Element} The RecordingContainer component.
 */
const RecordingContainer = () => {
  /**
   * @dispatch - Redux dispatch function to send actions to the store.
   * @visits - Visits from the global state.
   * @currentVisitId - Current visit ID from the global state.
   * @currentVisit - Current visit data from the global state.
   * @visitTypes - Visit types from the global state.
   * @transcript - Transcript data from the recording.
   * @startRecording - Function to start recording.
   * @stopRecording - Function to stop recording.
   * @isRecording - Flag to indicate if recording is active.
   * @status - State for the recording status.
   * @setStatus - Function to update the recording status.
   * @timer - State for the recording timer.
   * @setTimer - Function to update the recording timer.
   * @loadingText - State for the loading text.
   * @setLoadingText - Function to update the loading text.
   * @timerRef - Reference to the timer interval.
   */
  const dispatch = useDispatch();
  const visits = useSelector((state) => state.globalData.userData.visits);
  const currentVisitId = useSelector(
    (state) => state.globalData.currentState.currentVisitId,
  );
  const currentVisit = visits.find((visit) => visit._id === currentVisitId);
  const visitTypes = useSelector(
    (state) => state.globalData.userData.visitTypes,
  );

  const { startRecording, stopRecording, transcript, errors, isRecording } =
    audioTranscribe(currentVisit.visitTranscript || "");

  const [status, setStatus] = useState("start");
  const [timer, setTimer] = useState(0);
  const [loadingText, setLoadingText] = useState("Loading");
  const [latestTranscript, setLatestTranscript] = useState("");
  const timerRef = useRef(null);

  useEffect(() => {
    /**
     * Log's errors.
     */
    if (errors.length != 0) {
      dispatch(
        setNotification({
          name: "ERROR",
          description: "Something went wrong. Try again!",
          status: "error",
          duration: 5000,
          isClosable: true,
        }),
      );
    }
  }, [errors]);

  useEffect(() => {
    /**
     * Updates the timer if the status is 'recording'.
     */
    if (status === "recording") {
      timerRef.current = setInterval(() => {
        setTimer((prevTime) => prevTime + 1);
      }, 1000);
    } else {
      clearInterval(timerRef.current);
    }
    return () => clearInterval(timerRef.current);
  }, [status]);

  useEffect(() => {
    /**
     * Updates the loading text while status is 'loading'.
     */
    if (status === "loading") {
      const interval = setInterval(() => {
        setLoadingText((prev) => (prev.length < 10 ? prev + "." : "Loading"));
      }, 200);
      return () => clearInterval(interval);
    }
  }, [status]);

  useEffect(() => {
    /**
     * Sets the initial recording state to false and resets it on component unmount.
     */
    dispatch(setRecordingState(false));

    /**
     * Updates the status based on visitAudioTime on initial load.
     */
    if (currentVisit.visitAudioTime === 0) {
      setStatus("start");
    } else {
      setStatus("paused");
      setTimer(currentVisit.visitAudioTime);
    }

    return () => {
      dispatch(setRecordingState(false));
    };
  }, [currentVisitId]);

  useEffect(() => {
    /**
     * Updates the status to 'recording' when isRecording is true.
     */
    if (isRecording) {
      setStatus("recording");
    }
  }, [isRecording]);

  useEffect(() => {
    /**
     * Updates the latest transcript and dispatches it when the transcript changes.
     */
    if (transcript) {
      setLatestTranscript(transcript);
    }
  }, [transcript]);

  useEffect(() => {
    /**
     * Dispatches the latest transcript when it changes.
     */
    if (latestTranscript) {
      dispatch(setVisit(currentVisitId, { visitTranscript: latestTranscript }));
    }
  }, [latestTranscript, currentVisitId, dispatch]);

  useEffect(() => {
    /**
     * Updates the visitAudioTime whenever the timer changes.
     */
    if (status === "recording") {
      dispatch(setVisit(currentVisitId, { visitAudioTime: timer }));
    }
    if (timer === 5400) {
      setTimer(5401);
      dispatch(setVisit(currentVisitId, { visitAudioTime: 5401 }));
      handlePauseClick();
    } else if (timer === 5100) {
      dispatch(
        setNotification({
          name: "Auto-Pause",
          description:
            "This encounter will be paused in 5 minutes. To resume recording, click play.",
          status: "warning",
          duration: null,
          isClosable: true,
        }),
      );
    }
  }, [timer]);

  /**
   * Handles the start recording button click event.
   */
  const handleStartClick = async () => {
    try {
      if (!currentVisit.visitTypeId || currentVisit.visitTypeId === "") {
        dispatch(
          setNotification({
            name: "No Template Selected",
            description: "Template required to create a note",
            status: "warning",
            duration: 5000,
            isClosable: true,
          }),
        );
      } else {
        await navigator.mediaDevices.getUserMedia({ audio: true });
        dispatch(setRecordingState(true));
        setStatus("loading");
        setTimer(0);
        startRecording();
      }
    } catch (error) {
      dispatch(
        setNotification({
          name: "Microphone Access",
          description: "Please grant microphone access",
          status: "warning",
          duration: 5000,
          isClosable: true,
        }),
      );
    }
  };

  /**
   * Handles the pause recording button click event.
   */
  const handlePauseClick = async () => {
    try {
      stopRecording();
      setStatus("paused");
      dispatch(setRecordingState(false));
    } catch (error) {
      dispatch(
        setNotification({
          name: "Error",
          description: "An error occurred while pausing the recording",
          status: "error",
          duration: 5000,
          isClosable: true,
        }),
      );
      stopRecording();
      dispatch(setRecordingState(false));
    }
  };

  /**
   * Handles the resume recording button click event.
   */
  const handleResumeClick = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
    } catch (error) {
      dispatch(
        setNotification({
          name: "Microphone Access",
          description: "Please grant microphone access",
          status: "warning",
          duration: 5000,
          isClosable: true,
        }),
      );
    }

    try {
      setLatestTranscript(currentVisit.visitTranscript);
      dispatch(setRecordingState(true));
      setStatus("loading");
      startRecording();
    } catch (error) {
      dispatch(
        setNotification({
          name: "Error",
          description: "An error occurred while resuming the recording",
          status: "error",
          duration: 5000,
          isClosable: true,
        }),
      );
      stopRecording();
      dispatch(setRecordingState(false));
    }
  };

  /**
   * Handles the finish recording button click event.
   */
  const handleFinishClick = async () => {
    try {
      stopRecording();
      dispatch(setRecordingState(false));
      setStatus("loading");
      await dispatch(
        setVisit(currentVisitId, {
          visitTranscript: transcript,
          visitFinished: true,
        }),
      );

      const visitType = visitTypes.find(
        (type) => type._id === currentVisit.visitTypeId,
      );
      const isTranscriptInsufficient =
        currentVisit.visitTranscript.split(" ").length < 10;
      const newNotes = visitType.noteTypes.map((noteType) => ({
        _id: generateId(),
        noteName: noteType.noteTypeName,
        noteBody: isTranscriptInsufficient
          ? "[not enough data; insufficient transcript]"
          : "",
        noteTypeId: noteType._id,
        noteFirstTimeGenerated: !isTranscriptInsufficient,
        noteIsVisible: true,
      }));

      const response = await dispatch(
        setVisit(currentVisitId, { notes: newNotes }),
      );

      if (response) {
        dispatch(setRecordingState(false));
        dispatch(setCurrentState({ currentTab: "NOTES" }));
      }
    } catch (error) {
      stopRecording();
      dispatch(setRecordingState(false));
    }
  };

  /**
   * Formats the time from seconds to MM:SS format.
   * @param {number} seconds - The time in seconds.
   * @returns {string} - The formatted time.
   */
  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(mins).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
  };

  return (
    <div className="recordingcontainer-recordingcontainer">
      {status === "start" && (
        <DarkButton
          text="Start recording"
          image="/icons/microphone-white.svg"
          rootClassName="button-root-class-name3"
          onClick={handleStartClick}
        ></DarkButton>
      )}
      {status === "recording" && (
        <div className="recordingcontainer-pausefinishcontainer">
          <RecordingButton
            onClick={handlePauseClick}
            text={formatTime(timer)}
          ></RecordingButton>
          <FinishButton onClick={handleFinishClick}></FinishButton>
        </div>
      )}
      {status === "paused" && (
        <div className="recordingcontainer-pausefinishcontainer">
          <PausedButton
            onClick={handleResumeClick}
            text={formatTime(timer)}
          ></PausedButton>
          <FinishButton onClick={handleFinishClick}></FinishButton>
        </div>
      )}
      {status === "loading" && (
        <Button
          text={loadingText}
          rootClassName="button-root-class-name3 disabled"
        ></Button>
      )}
    </div>
  );
};

RecordingContainer.propTypes = {
  // Add PropTypes if required
};

export default RecordingContainer;
