/**
 * External Dependencies
 */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

/**
 * Internal Components
 */
import Button from "../../shared/button";

/**
 * Internal Dependencies
 * - Styles
 */
import "./frametranscript.css";

/**
 * FrameTranscript Component
 * @param {string} title - The title for the transcript.
 * @returns {JSX.Element} FrameTranscript component
 */
const FrameTranscript = ({ title }) => {
  /**
   * @currentVisitId - Current visit ID from the global state.
   * @userData - User data from the global state.
   * @buttonIconSrc - State for the button icon source.
   * @setButtonIconSrc - Function to update the button icon source.
   * @currentVisit - Current visit based on the current visit ID.
   * @transcript - Transcript of the current visit.
   */
  const currentVisitId = useSelector(
    (state) => state.globalData.currentState.currentVisitId,
  );
  const userData = useSelector((state) => state.globalData.userData);

  const [buttonIconSrc, setButtonIconSrc] = useState("/icons/copy.svg");

  const currentVisit = userData.visits.find(
    (visit) => visit._id === currentVisitId,
  );
  const transcript = currentVisit
    ? currentVisit.visitTranscript
    : "No transcript available";
  /**
   * Handles the click event to copy the transcript to the clipboard.
   */
  const handleCopyAllClick = async () => {
    try {
      await navigator.clipboard.writeText(
        transcript +
          "\n\nDisclaimer: This transcript was recorded with the help of Halo Medical Solutions, Inc. It is possible that typographical errors may have occurred. Any questions about content or clarifications should be directed to the medical provider.",
      );
      setButtonIconSrc("/icons/checkmark.svg");
      setTimeout(() => {
        setButtonIconSrc("/icons/copy.svg");
      }, 2000);
    } catch (error) {
      console.error("Error copying to clipboard:", error);
    }
  };

  /**
   * Parses the transcript and converts it to JSX elements.
   * @param {string} transcript - The transcript string to parse.
   * @returns {JSX.Element[]} - Array of JSX elements representing the parsed transcript.
   */
  // const parseTranscript = (transcript) => {
  //   return transcript.split('\n').map((line, index) => {
  //     const colonIndex = line.indexOf(':');
  //     if (colonIndex !== -1) {
  //       const label = line.slice(0, colonIndex + 1).toUpperCase();
  //       const content = line.slice(colonIndex + 1).trim();
  //       return (
  //         <div key={index} style={{ marginBottom: '0.75em' }}>
  //           <span style={{ fontSize: 'smaller', fontWeight: 'bold' }}>{label}</span> {content}
  //         </div>
  //       );
  //     }
  //     return (
  //       <div key={index} style={{ marginBottom: '0.75em' }}>
  //         {line}
  //       </div>
  //     );
  //   });
  // };

  return (
    <div className="frametranscript-frametranscript">
      <div className="frametranscript-container">
        <div className="frametranscript-transcript card1">
          <span className="td3">{title}</span>
          <span className="frametranscript-body td1">{transcript}</span>
        </div>
      </div>
      <Button
        image={buttonIconSrc}
        onClick={handleCopyAllClick}
        text="Copy all"
        rootClassName="button-root-class-name2"
      ></Button>
    </div>
  );
};

FrameTranscript.defaultProps = {
  title: "TRANSCRIPT",
};

FrameTranscript.propTypes = {
  title: PropTypes.string,
};

export default FrameTranscript;
