/**
 * External Dependencies
 */
import React from "react";
import PropTypes from "prop-types";

/**
 * Internal Dependencies
 * - Styles
 */
import "./unselectedvisititem.css";

/**
 * UnselectedVisitItem component for displaying an unselected visit item with a delete option.
 * @param {object} props - The component props.
 * @param {string} props.name - The name of the visit.
 * @param {function} props.onClick - The function to handle the click event.
 * @param {string} props.date - The date of the visit.
 * @param {function} props.onTrashClick - The function to handle the trash icon click.
 * @returns {JSX.Element} The UnselectedVisitItem component.
 */
const UnselectedVisitItem = ({ name, onClick, date, onTrashClick }) => {
  return (
    <div className="unselectedvisititem-unselectedvisititem" onClick={onClick}>
      <div className="unselectedvisititem-visitheader">
        <span className="td2 singlelinewrap">{name}</span>
        <span className="unselectedvisititem-date td2 singlelinewrap">
          {date}
        </span>
      </div>
      <img
        onClick={(e) => {
          e.stopPropagation();
          onTrashClick();
        }}
        alt="image"
        src="/icons/trash.svg"
        className="unselectedvisititem-trash img2"
      />
    </div>
  );
};

UnselectedVisitItem.defaultProps = {
  date: "6:40 PM • 47 min",
  name: "Keshav's follow up visit",
  onClick: () => {},
  onTrashClick: () => {},
};

UnselectedVisitItem.propTypes = {
  date: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
  onTrashClick: PropTypes.func,
};

export default UnselectedVisitItem;
