/**
 * External Dependencies
 */
import React from "react";
import PropTypes from "prop-types";

/**
 * Internal Dependencies
 * - Styles
 */
import "./subbutton2.css";

/**
 * SubButton2 Component
 * @param {string} image - Source URL for the button image.
 * @param {string} text - Text to display on the button.
 * @param {function} onClick - Click handler function for the button.
 * @param {string} rootClassName - Additional class name for root div.
 * @returns {JSX.Element} SubButton2 component
 */
const SubButton2 = ({ image, text, onClick, rootClassName }) => {
  return (
    <div onClick={onClick} className={`subbutton2-subbutton2 ${rootClassName}`}>
      <img src={image} className="subbutton2-image img1" />
      <span className="subbutton2-text td2">{text}</span>
    </div>
  );
};

SubButton2.defaultProps = {
  image: "/icons/trash.svg",
  text: "Delete",
  onClick: () => {},
  rootClassName: "",
};

SubButton2.propTypes = {
  image: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  rootClassName: PropTypes.string,
};

export default SubButton2;
