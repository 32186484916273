/**
 * External Dependencies
 */
import React from "react";
import PropTypes from "prop-types";

/**
 * Internal Dependencies
 * - Styles
 */
import "./pausedbutton.css";

/**
 * PausedButton component for rendering a paused button with text and an image.
 * @param {object} props - The component props.
 * @param {string} props.rootClassName - The root class name for styling.
 * @param {string} props.text - The button text.
 * @param {function} props.onClick - The function to handle click events.
 * @returns {JSX.Element} The PausedButton component.
 */
const PausedButton = ({ rootClassName, text, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={`pausedbutton-pausedbutton ${rootClassName}`}
    >
      <img alt="image" src="/icons/paused.svg" className="img1" />
      <span className="pausedbutton-text td2">{text}</span>
    </div>
  );
};

PausedButton.defaultProps = {
  text: "00:00",
  rootClassName: "",
  onClick: () => {},
};

PausedButton.propTypes = {
  text: PropTypes.string,
  rootClassName: PropTypes.string,
  onClick: PropTypes.func,
};

export default PausedButton;
