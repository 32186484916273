const rosReplacement = `
General: No fever, chills, fatigue, or weight changes
Eyes: No visual disturbances, pain, redness, or discharge
ENT: No hearing loss, tinnitus, sore throat, or nasal congestion
Respiratory: No cough, wheezing, shortness of breath, or chest discomfort
Cardiovascular: No chest pain, palpitations, or edema
Gastrointestinal: No nausea, vomiting, diarrhea, constipation, or abdominal pain
Genitourinary: No dysuria, frequency, urgency, hematuria, or incontinence
Musculoskeletal: No joint pain, stiffness, swelling, or muscle weakness
Neurologic: No headaches, dizziness, numbness, tingling, or seizures
Psychologic: No anxiety, depression, hallucinations, or mood changes
Integumentary: No rashes, lesions, itching, or skin color changes
{Assume every element of the review of systems is normal unless I indicate otherwise}
`;

const physexamReplacement = `
General: Otherwise healthy appearing @SEX@ in no apparent distress
HEENT: PERRLA, normal EOM, no conjunctival pallor, no scleral icterus, neck is supple
Respiratory: Breathing comfortably at rest, no use of accessory muscles, good air entry bilaterally, clear to bases, no wheezes, rhonchi, or rales
Cardiovascular: Skin is warm and dry, pulses present peripherally, no significant pedal edema, heart sounds normal without murmur
Gastrointestinal: Abdomen is soft, nontender, nondistended, no guarding, bowel sounds normal
Genitourinary: No CVA tenderness
Musculoskeletal: Grossly normal range of motion of all joints
Neurologic: Grossly no motor or sensory deficits
Hematologic/lymphatic: No contusions, ecchymosis, or lymphadenopathy
{Assume every element of the physical exam is normal unless I indicate otherwise}
`;

const hpiReplacement = `
{Write a clear HPI summarizing the patient’s chief complaint, onset, and key symptoms. Include relevant medical history. No more than 1 paragraph}
`;

export const replacementDictionary = {
  "@rosbyage@": rosReplacement,
  "@rosbya@": rosReplacement,
  "@reviewofsystemsbyage@": rosReplacement,
  "@reviewofsystems@": rosReplacement,
  "@ros@": rosReplacement,
  "@physexam@": physexamReplacement,
  "@physexambyage@": physexamReplacement,
  "@physexambya@": physexamReplacement,
  "@physicalexam@": physexamReplacement,
  "@physicalexambyage@": physexamReplacement,
  "@hpi@": hpiReplacement,
  "@sfhpi@": hpiReplacement,
};
