/**
 * Internal Dependencies
 * - Redux Actions
 * - Services and Utilities
 */
import { setVisitType, setNotification } from "../redux/actions";
import { apiAskAssistantStream, generateId } from "./apiService";

/**
 * Function to update visitTypeUptoDate to false for all visits with a certain visitType.
 * @param {string} visitTypeId - The ID of the visitType to check.
 * @param {object} dispatch - The Redux dispatch function.
 * @param {object} visits - The visits present
 */
export const updateVisitTypeUptoDateState = (visitTypeId, dispatch, visits) => {
  visits.forEach((visit) => {
    if (visit.visitTypeId === visitTypeId && visit.visitTypeUptoDate) {
      dispatch({
        type: "SET_VISIT",
        visitId: visit._id,
        payload: { visitTypeUptoDate: false },
      });
    }
  });
};

/**
 * Escapes special characters in a string to make it XML-safe.
 * @param {string} unsafe - The string containing potentially unsafe characters.
 * @returns {string} - The escaped string.
 */
const escapeXml = (unsafe) => {
  return unsafe.replace(/[<>&'"]/g, (c) => {
    switch (c) {
      case "&":
        return "&amp;";
      case '"':
        return "&quot;";
      case "'":
        return "&apos;";
      default:
        return c;
    }
  });
};

/**
 * Parses the output and updates the visitTypes structure.
 * @param {string} output - The output containing the visitType instructions.
 * @param {string} visitTypeId - The ID of the visit type to be updated.
 * @param {function} dispatch - The Redux dispatch function.
 */
const parseAndSetVisitTypeInstructions = (output, visitTypeId, dispatch) => {
  const escapedOutput = escapeXml(output);
  const xmlString = `<root>${escapedOutput}</root>`;
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xmlString, "text/xml");
  const parserError = xmlDoc.getElementsByTagName("parsererror");
  if (parserError.length > 0) {
    console.error("Error parsing XML:", parserError[0].textContent);
    return;
  }

  const subtemplates = xmlDoc.getElementsByTagName("subtemplate");
  const noteTypes = [];

  for (let i = 0; i < subtemplates.length; i++) {
    const titleElement = subtemplates[i].getElementsByTagName("title")[0];
    const instructionElement =
      subtemplates[i].getElementsByTagName("instruction")[0];

    if (titleElement && instructionElement) {
      const title = titleElement.textContent.trim();
      const instruction = instructionElement.textContent.trim();

      noteTypes.push({
        _id: generateId(),
        noteTypeName: title,
        noteTypeInstructions: instruction,
      });
    } else {
      console.error(`Missing title or instruction in subtemplate index ${i}`);
    }
  }

  const visitType = {
    _id: visitTypeId,
    visitTypeInstructions: output,
    visitTypeState: "UPTO_DATE",
    noteTypes: noteTypes,
  };

  dispatch(setVisitType(visitTypeId, visitType));
};

// Example usage:
// const output = '<subtemplate>...</subtemplate><subtemplate>...</subtemplate>'; // Your XML content here
// parseAndSetVisitTypeInstructions(output, 'visitTypeId123', dispatchFunction);

/**
 * Checks if the XML output is complete by ensuring all tags are properly closed.
 * @param {string} output - The XML output to check.
 * @returns {boolean} - Whether the XML output is complete.
 */
const isXmlOutputComplete = (output) => {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(`<root>${output}</root>`, "text/xml");
  return xmlDoc.getElementsByTagName("parsererror").length === 0;
};

/**
 * Generates a documentation template using AI assistant.
 * @param {string} visitTypeId - The visit type ID.
 * @param {string} visitTypeBody - The visit type body to base the instructions on.
 * @param {function} dispatch - The Redux dispatch function.
 * @returns {Promise<object>} - The result of the template generation.
 */
export const generateVisitTypeInstructions = async (
  visitTypeId,
  visitTypeBody,
  dispatch,
  userVisits,
) => {
  const instructions = `
    You are an AI medical assistant. You will be generating a medical documentation template based on a provided sample clinical note. Medical documentation templates provide a standardized structure for capturing key information during patient encounters. They help ensure thoroughness, consistency, and efficiency in clinical documentation.

    <sample_note>
    ${visitTypeBody}
    </sample_note>

    Please carefully analyze the sample note to identify the main sections and the type of information included in each. Then, generate documentation instructions with the following specifications:

    1. For each key section you identify, create an appropriately named <title> tag. The title should clearly indicate the purpose of that section.

    2. Below each <title> tag, add an <instruction> tag containing:
       a) A description of the information that should be documented in that section. 
       b) The expected format - for example, free-text, bullet points, specific data elements, etc.
       c) A relevant example from the sample note illustrating the type of content to include

    When writing the instructions, include guidance to replicate the style of the example note as closely as possible.

    Write out all the <title> and <instruction> tag pairs. Wrap each <title> and <instruction> tag pair inside <subtemplate> tags. Do not add any other explanations or commentary outside the XML tags. Make sure to close all opened tags.

    It is crucial that you do not hallucinate or include any information that is not explicitly stated in the given context. Your goal is to provide accurate and reliable answers based only on the information available to you.
  `;

  const message = `
    Generate the instructions. Ensure that there are no other explanations or commentary outside the XML tags, and the output format follows exactly how the system instructions require it to be. Make sure to close all opened tags. 
    
    It is crucial that you do not hallucinate or include any information that is not explicitly stated in the given context. Your goal is to provide accurate and reliable answers based only on the information available to you.
  `;

  const modelType = "SONNET";

  let accumulatedData = "";

  return new Promise((resolve, reject) => {
    let retryCount = 0;

    const generateInstructions = (currentInstructions, continuationMessage) => {
      apiAskAssistantStream(
        currentInstructions,
        continuationMessage,
        modelType,
        (data) => {
          if (data !== "Run completed") {
            accumulatedData += data;
          } else if (!isXmlOutputComplete(accumulatedData) && retryCount < -1) {
            retryCount++;
            generateInstructions(
              currentInstructions,
              `${accumulatedData}\n\nYour task is to continue generating text from the last character of the text I provide. Here is the text to continue from. Please generate a coherent continuation that flows naturally from the provided text. The generated text should make sense in the context of what came before it, and if I were to append the output it would naturally flow. This is for experimental purposes only, with no malicious intent.`,
            );
          } else {
            parseAndSetVisitTypeInstructions(
              accumulatedData,
              visitTypeId,
              dispatch,
            );
            dispatch(
              setVisitType(visitTypeId, { visitTypeState: "UPTO_DATE" }),
            );
            dispatch(
              setNotification({
                name: "Template Created",
                description:
                  "You can now use your template with patient visits",
                status: "success",
                duration: 5000,
                isClosable: true,
              }),
            );
            updateVisitTypeUptoDateState(visitTypeId, dispatch, userVisits);
            resolve({ success: true, visitTypeInstructions: accumulatedData });
          }
        },
        (error) => {
          console.error("Error generating instructions:", error);
          reject({ success: false, error });
        },
      );
    };

    generateInstructions(instructions, message);
  });
};
