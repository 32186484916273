/**
 * External Dependencies
 */
import React, { useEffect, useState, useRef } from "react";

/**
 * Internal Components
 */
import "./microphonetestcontainer.css";

/**
 * MicrophoneTestContainer component for testing microphone input and displaying volume level with bars.
 * @returns {JSX.Element} The MicrophoneTestContainer component.
 */
const MicrophoneTestContainer = () => {
  /**
   * @containerRef - Reference to the container DOM element.
   * @volumeLevel - State for the microphone volume level.
   * @setVolumeLevel - Function to update the microphone volume level state.
   */
  const containerRef = useRef(null);
  const [volumeLevel, setVolumeLevel] = useState(0);

  useEffect(() => {
    /**
     * Starts recording and updates the volume level.
     */
    const startRecording = () => {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          const audioContext = new (window.AudioContext ||
            window.webkitAudioContext)();
          const source = audioContext.createMediaStreamSource(stream);
          const analyser = audioContext.createAnalyser();
          source.connect(analyser);
          analyser.fftSize = 256;
          const bufferLength = analyser.frequencyBinCount;
          const dataArray = new Uint8Array(bufferLength);

          /**
           * Updates the volume level based on audio input.
           */
          const updateVolumeLevel = () => {
            analyser.getByteFrequencyData(dataArray);
            const volume = dataArray.reduce((a, b) => a + b, 0) / bufferLength;
            setVolumeLevel(Math.round(volume));
            requestAnimationFrame(updateVolumeLevel);
          };

          updateVolumeLevel();
        })
        .catch((err) => {
          console.error("Error accessing microphone:", err);
        });
    };

    startRecording();
  }, []);

  /**
   * Renders the volume bars based on the current volume level.
   * @returns {JSX.Element[]} An array of JSX elements representing the volume bars.
   */
  const renderVolumeBars = () => {
    const bars = [];
    const maxBars = 8;
    const thresholds = [0, 5, 15, 35, 65, 95, 125, 155];

    const activeBars = thresholds.reduce(
      (acc, threshold) => (volumeLevel > threshold ? acc + 1 : acc),
      0,
    );

    for (let i = 0; i < maxBars; i++) {
      bars.push(
        <div
          key={i}
          className={`microphonetestcontainer-bar ${i < activeBars ? "active" : ""}`}
        ></div>,
      );
    }

    return bars;
  };

  return (
    <div className="microphonetestcontainer" ref={containerRef}>
      <div className="microphonetestcontainer-header">
        <span className="tl1">Microphone Test</span>
        <span className="spacer"></span>
        <div className="microphonetestcontainer-bars">{renderVolumeBars()}</div>
      </div>
    </div>
  );
};

export default MicrophoneTestContainer;
