/**
 * External Dependencies
 */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  useToast,
  Box,
  Text,
  Flex,
  Image,
  CloseButton,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";

/**
 * Internal Dependencies
 * - Styles
 */
import "./footer.css";

/**
 * Footer Component
 * @param {string} text - Text to display in the footer.
 * @returns {JSX.Element} Footer component
 */
const Footer = (props) => {
  /**
   * @toast - Toast to display notifications.
   * @globalData - Current tab from the global state.
   * @currentDisplaySidebar - Modify css properties accordingly
   */
  const toast = useToast();
  const globalData = useSelector((state) => state.globalData);
  const currentDisplaySidebar = useSelector(
    (state) => state.globalData.currentState.currentDisplaySidebar,
  );

  /**
   * Gets the notification styles based on the status.
   * @param {string} status - The status of the notification.
   * @returns {object} - The styles for the notification.
   */
  const getNotificationStyles = (status) => {
    switch (status) {
      case "success":
        return {
          background: "#edf7ed",
          color: "#3e6241",
          image: "/icons/toastsuccess.svg",
        };
      case "error":
        return {
          background: "#fdeded",
          color: "#662b2a",
          image: "/icons/toasterror.svg",
        };
      case "warning":
        return {
          background: "#fff4e5",
          color: "#724b14",
          image: "/icons/toastwarning.svg",
        };
      case "info":
        return {
          background: "#e5f6fd",
          color: "#074765",
          image: "/icons/toastinfo.svg",
        };
      default:
        return {
          background: "white",
          color: "black",
          image: "/icons/info.svg",
        };
    }
  };

  /**
   * Displays a notification toast based on the global state.
   */
  useEffect(() => {
    const notification = globalData.currentState.currentNotifications;
    if (notification && notification.name !== "") {
      const styles = getNotificationStyles(notification.status);
      const position =
        window.innerWidth <= 740
          ? "top"
          : notification.status === "error"
            ? "top"
            : "bottom-right";
      const transform =
        position === "top" ? "translate(0px, 24px)" : "translate(-24px, -24px)";

      toast({
        position: position,
        duration: notification.duration,
        isClosable: notification.isClosable,
        render: ({ onClose }) => (
          <Box
            p={3}
            borderRadius="md"
            boxShadow="none"
            width={window.innerWidth <= 740 ? "100%" : "360px"}
            maxWidth="100%"
            opacity={0.9}
            fontFamily="Inter"
            bg={styles.background}
            color={styles.color}
            transform={transform}
          >
            <Flex alignItems="center" gap="16px">
              <Image src={styles.image} alt="info icon" boxSize="20px" />
              <Box flex="1" display="flex" flexDirection="column">
                <Text fontSize="15px" fontWeight="semibold">
                  {notification.name}
                </Text>
                <Text fontSize="13px" fontWeight="regular">
                  {notification.description}
                </Text>
              </Box>
              <CloseButton alignSelf="center" onClick={onClose} size="sm" />
            </Flex>
          </Box>
        ),
      });
    }
  }, [globalData.currentState.currentNotifications, toast]);

  return (
    <>
      <div
        className={`footer-footer footer-hide ${currentDisplaySidebar ? "footer-hide" : ""}`}
      >
        <span className="footer-text td2">{props.text}</span>
      </div>
      <div className="landscape-background">
        <span className="td1 landscape-text">
          Please rotate your device to portrait mode to view this application.
        </span>
      </div>
    </>
  );
};

Footer.defaultProps = {
  text: "Halo may produce inaccurate information about the patient visits. Please review the information for accuracy.",
};

Footer.propTypes = {
  text: PropTypes.string,
};

export default Footer;
