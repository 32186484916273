/**
 * External Dependencies
 */
import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";

/**
 * Internal Components
 */
import SubButton from "../../shared/subbutton";

/**
 * Internal Dependencies
 * - Styles
 * - PrimeReact Components
 * - Custom Hooks
 * - Redux Actions
 */
import "./patientcontextcontainer.css";
import { Tooltip } from "primereact/tooltip";
import { useWhisper } from "@chengsokdara/use-whisper";
import { useSelector, useDispatch } from "react-redux";
import { setVisit } from "../../../redux/actions";

/**
 * PatientContextContainer component for adding context to the patient's visit.
 * @param {object} props - The component props.
 * @param {string} props.text - The header text.
 * @param {string} props.textareaPlaceholder - The placeholder for the textarea.
 * @returns {JSX.Element} The PatientContextContainer component.
 */
const PatientContextContainer = ({ text, textareaPlaceholder }) => {
  /**
   * @dispatch - Redux dispatch function to send actions to the store.
   * @containerRef - Reference to the container DOM element.
   * @currentVisitId - Current visit ID from the global state.
   * @currentVisit - Current visit data from the global state.
   * @showTextarea - State for showing the textarea.
   * @setShowTextarea - Function to update the show textarea state.
   * @isDictating - State for the dictation status.
   * @setIsDictating - Function to update the dictation status.
   * @dictationIcon - State for the dictation icon source.
   * @setDictationIcon - Function to update the dictation icon source.
   * @patientContext - State for the patient context text.
   * @setPatientContext - Function to update the patient context text.
   * @dictatedPatientContext - State for the dictated patient context text.
   * @setDictatedPatientContext - Function to update the dictated patient context text.
   * @recording - Flag to indicate if recording is active.
   * @transcript - Transcript data from the recording.
   * @startRecording - Function to start recording.
   * @stopRecording - Function to stop recording.
   */
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const currentVisitId = useSelector(
    (state) => state.globalData.currentState.currentVisitId,
  );
  const currentVisit = useSelector((state) =>
    state.globalData.userData.visits.find(
      (visit) => visit._id === currentVisitId,
    ),
  );

  const [showTextarea, setShowTextarea] = useState(false);
  const [isDictating, setIsDictating] = useState(false);
  const [dictationIcon, setDictationIcon] = useState("/icons/microphone.svg");
  const [patientContext, setPatientContext] = useState("");
  const [dictatedPatientContext, setDictatedPatientContext] = useState("");

  const { recording, transcript, startRecording, stopRecording } = useWhisper({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    streaming: true,
    nonStop: true,
    timeSlice: 1000,
    whisperConfig: { language: "en" },
  });

  useEffect(() => {
    if (currentVisit?.visitAdditionalPatientContext) {
      setPatientContext(currentVisit.visitAdditionalPatientContext);
      setShowTextarea(true);
    }
  }, [currentVisit]);

  useEffect(() => {
    if (isDictating) {
      setDictatedPatientContext(transcript.text);
    }
  }, [transcript.text]);

  useEffect(() => {
    if (isDictating && !recording) {
      handleDictateClick();
    }
  }, [recording]);

  useEffect(() => {
    /**
     * Handles click outside event to hide the textarea if the context is empty.
     * @param {object} event - The click event.
     */
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        if (!patientContext) {
          setShowTextarea(false);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [patientContext]);

  /**
   * Handles the SubButton click to show the textarea.
   */
  const handleSubButtonClick = () => {
    setShowTextarea(true);
  };

  /**
   * Handles the dictate button click to start/stop dictation.
   */
  const handleDictateClick = () => {
    if (isDictating) {
      stopRecording();
      setIsDictating(false);
      setDictationIcon("/icons/microphone.svg");
      setPatientContext(
        (prevContext) => prevContext + (dictatedPatientContext || ""),
      );
      setDictatedPatientContext("");
    } else {
      setDictatedPatientContext("");
      startRecording();
      setIsDictating(true);
      setDictationIcon("/icons/mute.svg");
    }
  };

  /**
   * Handles changes to the textarea.
   * @param {object} event - The change event.
   */
  const handleTextareaChange = (event) => {
    const textAreaBody = event.target.value;
    setPatientContext(textAreaBody);
    dispatch(
      setVisit(currentVisitId, { visitAdditionalPatientContext: textAreaBody }),
    );
  };

  return (
    <div
      className="patientcontextcontainer-patientcontextcontainer"
      ref={containerRef}
    >
      <div className="patientcontextcontainer-header">
        <span className="patientcontextcontainer-text tl1">{text}</span>
        <img
          alt="info"
          src="/icons/question.svg"
          className="selectednote-image img2 info-icon"
          data-pr-tooltip="[optional] You can add context (intake info)about your patient before starting the encounter."
          data-pr-position="top"
        />
        <Tooltip target=".info-icon" className="custom-tooltip" />
        <span className="spacer"></span>

        {!showTextarea && (
          <SubButton
            image="/icons/plus.svg"
            onClick={handleSubButtonClick}
          ></SubButton>
        )}

        {showTextarea && window.innerWidth > 740 && (
          <img
            alt="dictation"
            src={dictationIcon}
            className="patientcontextcontainer-image img2 mic-icon"
            data-pr-tooltip="Dictate"
            data-pr-position="top"
            onClick={handleDictateClick}
          />
        )}
      </div>
      {showTextarea && (
        <div className="patientcontextcontainer-textareacontainer">
          <textarea
            placeholder={textareaPlaceholder}
            className="textarea td0"
            value={
              (patientContext ? patientContext : "") +
              (dictatedPatientContext ? dictatedPatientContext : "")
            }
            onChange={handleTextareaChange}
            disabled={isDictating}
          ></textarea>
          <Tooltip target=".mic-icon" className="custom-tooltip" />
        </div>
      )}
    </div>
  );
};

PatientContextContainer.defaultProps = {
  text: "Pre-visit notes",
  textareaPlaceholder:
    "You can add context about your patient before starting the encounter.\n\nEx: 28yo woman with diabetes.",
};

PatientContextContainer.propTypes = {
  text: PropTypes.string,
  textareaPlaceholder: PropTypes.string,
};

export default PatientContextContainer;
