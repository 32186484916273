/**
 * External Dependencies
 */
import React from "react";
import PropTypes from "prop-types";

/**
 * Internal Dependencies
 * - Styles
 */
import "./activetoggle.css";

/**
 * Activetoggle Component
 * @param {object} props - Component props
 * @param {string} props.text - Text to display
 * @param {string} props.image - Image source
 * @param {function} props.onClick - Click handler
 * @returns {JSX.Element} Activetoggle component
 */
const Activetoggle = ({ image, text, onClick }) => {
  return (
    <div className="activetoggle-activetoggle" onClick={onClick}>
      <img src={image} alt="image" className="img1" />
      <span className="activetoggle-text td2">{text}</span>
    </div>
  );
};

Activetoggle.defaultProps = {
  text: "ICD-10",
  image: "/icons/code2.svg",
};

Activetoggle.propTypes = {
  text: PropTypes.string,
  image: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default Activetoggle;
