/**
 * Redux Actions
 */

/**
 * Sets the current state.
 * @param {object} payload - The current state payload.
 * @returns {object} The action object.
 */
export const setCurrentState = (payload) => ({
  type: "SET_CURRENT_STATE",
  payload,
});

/**
 * Sets the user data.
 * @param {object} payload - The user data payload.
 * @returns {object} The action object.
 */
export const setUserData = (payload) => ({
  type: "SET_USER_DATA",
  payload,
});

/**
 * Sets the visit data.
 * @param {string} visitId - The visit ID.
 * @param {object} payload - The visit data payload.
 * @returns {object} The action object.
 */
export const setVisit = (visitId, payload) => ({
  type: "SET_VISIT",
  visitId,
  payload,
});

/**
 * Deletes a visit.
 * @param {string} visitId - The visit ID.
 * @returns {object} The action object.
 */
export const deleteVisit = (visitId) => ({
  type: "DELETE_VISIT",
  visitId,
});

/**
 * Sets the note data.
 * @param {string} visitId - The visit ID.
 * @param {string} noteId - The note ID.
 * @param {object} payload - The note data payload.
 * @returns {object} The action object.
 */
export const setNote = (visitId, noteId, payload) => ({
  type: "SET_NOTE",
  visitId,
  noteId,
  payload,
});

/**
 * Deletes a note.
 * @param {string} visitId - The visit ID.
 * @param {string} noteId - The note ID.
 * @returns {object} The action object.
 */
export const deleteNote = (visitId, noteId) => ({
  type: "DELETE_NOTE",
  visitId,
  noteId,
});

/**
 * Adds a new visit.
 * @param {object} payload - The visit data payload.
 * @returns {object} The action object.
 */
export const addVisit = (payload) => ({
  type: "ADD_VISIT",
  payload,
});

/**
 * Adds a new note.
 * @param {string} visitId - The visit ID.
 * @param {object} payload - The note data payload.
 * @returns {object} The action object.
 */
export const addNote = (visitId, payload) => ({
  type: "ADD_NOTE",
  visitId,
  payload,
});

/**
 * Adds a new visit type.
 * @param {object} payload - The visit type data payload.
 * @returns {object} The action object.
 */
export const addVisitType = (payload) => ({
  type: "ADD_VISIT_TYPE",
  payload,
});

/**
 * Adds a new note type.
 * @param {string} visitTypeId - The visit type ID.
 * @param {object} payload - The note type data payload.
 * @returns {object} The action object.
 */
export const addNoteType = (visitTypeId, payload) => ({
  type: "ADD_NOTE_TYPE",
  visitTypeId,
  payload,
});

/**
 * Sets the visit type data.
 * @param {string} visitTypeId - The visit type ID.
 * @param {object} payload - The visit type data payload.
 * @returns {object} The action object.
 */
export const setVisitType = (visitTypeId, payload) => ({
  type: "SET_VISIT_TYPE",
  visitTypeId,
  payload,
});

/**
 * Deletes a visit type.
 * @param {string} visitTypeId - The visit type ID.
 * @returns {object} The action object.
 */
export const deleteVisitType = (visitTypeId) => ({
  type: "DELETE_VISIT_TYPE",
  visitTypeId,
});

/**
 * Sets the note type data.
 * @param {string} visitTypeId - The visit type ID.
 * @param {string} noteTypeId - The note type ID.
 * @param {object} payload - The note type data payload.
 * @returns {object} The action object.
 */
export const setNoteType = (visitTypeId, noteTypeId, payload) => ({
  type: "SET_NOTE_TYPE",
  visitTypeId,
  noteTypeId,
  payload,
});

/**
 * Deletes a note type.
 * @param {string} visitTypeId - The visit type ID.
 * @param {string} noteTypeId - The note type ID.
 * @returns {object} The action object.
 */
export const deleteNoteType = (visitTypeId, noteTypeId) => ({
  type: "DELETE_NOTE_TYPE",
  visitTypeId,
  noteTypeId,
});

/**
 * Sets the notification data.
 * @param {object} payload - The notification data payload.
 * @returns {object} The action object.
 */
export const setNotification = (payload) => ({
  type: "SET_NOTIFICATION",
  payload,
});

/**
 * Sets the recording state.
 * @param {boolean} isRecording - The recording state.
 * @returns {object} The action object.
 */
export const setRecordingState = (isRecording) => ({
  type: "SET_RECORDING_STATE",
  payload: isRecording,
});

/**
 * Adds a new diagnosis.
 * @param {string} visitId - The visit ID.
 * @param {object} payload - The diagnosis data payload.
 * @returns {object} The action object.
 */
export const addDiagnoses = (visitId, payload) => ({
  type: "ADD_DIAGNOSES",
  visitId,
  payload,
});

/**
 * Sets the diagnosis data.
 * @param {string} visitId - The visit ID.
 * @param {string} diagnosesId - The diagnosis ID.
 * @param {object} payload - The diagnosis data payload.
 * @returns {object} The action object.
 */
export const setDiagnoses = (visitId, diagnosesId, payload) => ({
  type: "SET_DIAGNOSES",
  visitId,
  diagnosesId,
  payload,
});

/**
 * Deletes a diagnosis.
 * @param {string} visitId - The visit ID.
 * @param {string} diagnosesId - The diagnosis ID.
 * @returns {object} The action object.
 */
export const deleteDiagnoses = (visitId, diagnosesId) => ({
  type: "DELETE_DIAGNOSES",
  visitId,
  diagnosesId,
});
