import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import store from "./redux/store";

import "./style.css";
import Login from "./views/login";
import Downloads from "./views/downloads";
import Dashboard from "./views/dashboard";
import NotFound from "./views/not-found";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { ChakraProvider } from "@chakra-ui/react";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";

const App = () => {
  return (
    <>
      <ChakraProvider>
        <Provider store={store}>
          <Router>
            <Switch>
              <Route component={Login} exact path="/" />
              <Route component={Login} exact path="/login" />
              <Route component={Downloads} exact path="/downloads" />
              <Route component={Downloads} exact path="/help" />
              <Route component={Dashboard} exact path="/dashboard" />
              <Route component={NotFound} path="**" />
              <Redirect to="**" />
            </Switch>
          </Router>
        </Provider>
      </ChakraProvider>
      <Analytics />
      <SpeedInsights />
    </>
  );
};

const rootElement = document.getElementById("app");
const root = createRoot(rootElement);
root.render(<App />);
