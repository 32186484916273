/**
 * External Dependencies
 */
import React from "react";
import PropTypes from "prop-types";

/**
 * Internal Components
 */
import CircleIconDark from "../../shared/circleicondark";

/**
 * Internal Dependencies
 * - Styles
 */
import "./askaiinput.css";

/**
 * AskAiInput component for handling user input and submission.
 * @param {object} props - The component props.
 * @param {string} props.rootClassName - The root class name for styling.
 * @param {string} props.placeholder - The placeholder text for the input.
 * @param {string} props.value - The value of the input.
 * @param {function} props.onChange - The function to handle input change.
 * @param {function} props.onSubmit - The function to handle input submission.
 * @returns {JSX.Element} The AskAiInput component.
 */
const AskAiInput = ({
  rootClassName,
  placeholder,
  value,
  onChange,
  onSubmit,
}) => {
  /**
   * @handleKeyPress - Handles key press event.
   * @handleOnSubmitClick - Handles submit button click event.
   */
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      onSubmit();
    }
  };

  const handleOnSubmitClick = () => {
    onSubmit();
  };

  return (
    <div className={`askaiinput-askaiinput ${rootClassName}`}>
      <img
        alt="image"
        src="/icons/star.svg"
        className="askaiinput-image img3"
      />
      <input
        type="text"
        placeholder={placeholder}
        className="td1 input"
        value={value}
        onChange={onChange}
        onKeyPress={handleKeyPress}
      />
      <CircleIconDark
        imageSrc="/icons/arrow.svg"
        className=""
        onClick={handleOnSubmitClick}
      />
    </div>
  );
};

AskAiInput.defaultProps = {
  rootClassName: "",
  placeholder: "Type here...",
  value: "",
  onChange: () => {},
  onSubmit: () => {},
};

AskAiInput.propTypes = {
  rootClassName: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default AskAiInput;
