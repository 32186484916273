/**
 * Internal Dependencies
 * - Redux Actions
 * - Services and Utilities
 */
import { setNote, setNotification } from "../redux/actions";
import { apiAskAssistantStream } from "./apiService";

/**
 * Streams the AI-generated response with a typewriter effect.
 * @param {string} text - The text to be typed out.
 * @param {object} queueRef - Reference to the queue array.
 * @param {object} isTypingRef - Reference to the isTyping flag.
 * @param {object} noteBodyRef - Reference to the current note body.
 * @param {string} currentVisitId - The current visit ID.
 * @param {string} currentNoteId - The current note ID.
 * @param {function} dispatch - The Redux dispatch function.
 */

// const typeWriterEffect = (
//   text,
//   queueRef,
//   isTypingRef,
//   noteBodyRef,
//   currentVisitId,
//   currentNoteId,
//   dispatch
// ) => {
//   let index = 0;
//   const typeWriter = () => {
//     if (index < text.length) {
//       noteBodyRef.current += text.charAt(index);
//       dispatch(setNote(currentVisitId, currentNoteId, { noteBody: noteBodyRef.current }));
//       index++;
//       setTimeout(typeWriter, 0);
//     } else {
//       isTypingRef.current = false;
//       processQueue(queueRef, isTypingRef, noteBodyRef, currentVisitId, currentNoteId, dispatch);
//     }
//   };
//   typeWriter();
// };

const typeWriterEffect = (
  text,
  queueRef,
  isTypingRef,
  noteBodyRef,
  currentVisitId,
  currentNoteId,
  dispatch,
) => {
  // Instantly add the text to the note body
  noteBodyRef.current += text;

  // Dispatch the update
  dispatch(
    setNote(currentVisitId, currentNoteId, { noteBody: noteBodyRef.current }),
  );

  // Indicate that typing is done and process the queue
  isTypingRef.current = false;
  processQueue(
    queueRef,
    isTypingRef,
    noteBodyRef,
    currentVisitId,
    currentNoteId,
    dispatch,
  );
};

/**
 * Processes the queue and initiates the typewriter effect for the next item.
 * @param {object} queueRef - Reference to the queue array.
 * @param {object} isTypingRef - Reference to the isTyping flag.
 * @param {object} noteBodyRef - Reference to the current note body.
 * @param {string} currentVisitId - The current visit ID.
 * @param {string} currentNoteId - The current note ID.
 * @param {function} dispatch - The Redux dispatch function.
 */
const processQueue = (
  queueRef,
  isTypingRef,
  noteBodyRef,
  currentVisitId,
  currentNoteId,
  dispatch,
) => {
  if (queueRef.current.length > 0 && !isTypingRef.current) {
    isTypingRef.current = true;
    const text = queueRef.current.shift();
    typeWriterEffect(
      text,
      queueRef,
      isTypingRef,
      noteBodyRef,
      currentVisitId,
      currentNoteId,
      dispatch,
    );
  }
};

/**
 * Generates a note using AI assistant and streams the result with a typewriter effect.
 * @param {string} currentVisitId - The current visit ID.
 * @param {string} currentNoteId - The current note ID.
 * @param {string} visitTranscript - The visit transcript.
 * @param {string} visitAdditionalPatientContext - Additional context for the patient.
 * @param {string} noteInstructions - Instructions for the AI assistant.
 * @param {string} noteLastNote - The last note content.
 * @param {string} userCommand - The command for the AI assistant.
 * @param {string} modelType - The model type for the AI assistant.
 * @param {function} dispatch - The Redux dispatch function.
 * @param {function} setIsLoading - Function to set the loading state.
 * @returns {Promise<object>} - The result of the note generation.
 */
export const generateNote = async (
  currentVisitId,
  currentNoteId,
  visitTranscript,
  visitAdditionalPatientContext,
  noteTypeBodyType,
  noteInstructions,
  noteLastNote,
  userCommand,
  modelType,
  dispatch,
  setIsLoading,
) => {
  const queueRef = { current: [] };
  const isTypingRef = { current: false };
  const noteBodyRef = { current: "" };
  let firstMessageReceived = false;

  const exampleInstructions = `
You are an AI medical scribe tasked with generating a concise and accurate clinical note based on a transcript of a patient encounter, additional context, and specific instructions from the user.

Here is the transcript of the clinical encounter:
<transcript>
${visitTranscript}
</transcript>

Here is the additional context:
<additional_context>
${visitAdditionalPatientContext}
</additional_context>

Here are the specific instructions to generate the note. There's an example inside the instructions as well, adhere to the style and structure of that example. Ensure that the resulting output closely mirrors and replicates the original style and structure, while also adjusting to incorporate the pertinent context from <transcript> and <additional_context> provided.
<instructions>
${noteInstructions}
</instructions>

To generate the note, carefully review the transcript and additional context provided. If a last note is available, use that as the starting point. Otherwise, create a new note from scratch. To generate a high quality note, make sure you are following the stylistic and structural choices of the example inside <instructions>. Replace the patient's name with the word "patient."

Follow the user's specific command to edit or modify the note accordingly. Make targeted changes based on their instructions, and keep EVERYTHING ELSE THE SAME. Do not add information beyond what is included in the transcript and context.
    
If a last note is provided, use that as the primary reference for generating the note. Perform only the specific edits or modifications based on the user's command. Do not generate a new note from scratch; make changes solely on the existing note provided. Ensure all other content remains unchanged. 

If there is insufficient information provided to generate a complete note, simply write [not enough data]. Do not try to fill in gaps with outside knowledge.

Your final output should contain ONLY the body of the note, without any additional labels or tags. Do not include phrases like "Here is the note:" before your response.
  
It is crucial that you do not hallucinate or include any information that is not explicitly stated in the given context. Your goal is to provide accurate and reliable answers based only on the information available to you. If there is insufficient information provided to generate a complete note, simply write [not enough data]. Do not try to fill in gaps with outside knowledge.

The output should be only in English.`;
  const templateInstructions = ``;

  const exampleMessage = `
Here is the last note: 
<last_note>
${noteLastNote}
</last_note>

Here are the commands that the user wants to be executed. 
<command>
${userCommand}
</command>

To generate the note, carefully review the transcript and additional context provided. If a last note is available, use that as the starting point. Otherwise, create a new note from scratch. To generate a high quality note, make sure you are following the stylistic and structural choices of the example inside <instructions>. Replace the patient's name with the word "patient."

Follow the user's specific command to edit or modify the note accordingly. Make targeted changes based on their instructions, and keep EVERYTHING ELSE THE SAME. Do not add information beyond what is included in the transcript and context.
    
If a last note is provided, use that as the primary reference for generating the note. Perform only the specific edits or modifications based on the user's command. Do not generate a new note from scratch; make changes solely on the existing note provided. Ensure all other content remains unchanged. 

If there is insufficient information provided to generate a complete note, simply write [not enough data]. Do not try to fill in gaps with outside knowledge.

Your final output should contain ONLY the body of the note, without any additional labels or tags. Do not include phrases like "Here is the note:" before your response.
  
It is crucial that you do not hallucinate or include any information that is not explicitly stated in the given context. Your goal is to provide accurate and reliable answers based only on the information available to you. If there is insufficient information provided to generate a complete note, simply write [not enough data]. Do not try to fill in gaps with outside knowledge.

The output should be only in English.`;
  const templateMessage = `
You are an AI medical scribe tasked with generating a concise and accurate clinical note. Your responsibility is to use the provided information to create or modify a clinical note according to specific rules and user commands. Follow these instructions carefully:

1. Review the following information:

<transcript>
${visitTranscript}
</transcript>

<additional_context>
${visitAdditionalPatientContext}
</additional_context>

${noteInstructions}

<last_note>
${noteLastNote}
</last_note>

<user_command>
${userCommand}
</user_command>

2. If the <last_note> is not blank, use it as your starting point. If it is blank, create a new note from scratch using the <template> as a guide.

3. Carefully follow the <rule_set> when modifying the note. Only make changes that are allowed by the rules.

4. Incorporate information from the <transcript> and <additional_context> into the note. Ensure that all added information is accurate and directly supported by these sources.

5. Follow the <user_command> to make specific edits or modifications to the note. Make only the changes requested in the command, keeping everything else the same.

6. Do not include any information that is not explicitly stated in the <additional_context> or <transcript>. Avoid making assumptions or adding details based on outside knowledge.

7. Your final output should contain only the body of the clinical note. Do not include any labels, tags, or phrases like "Here is the note:" before your response.

8. Double-check your work to ensure you have followed all rules and instructions accurately.

9. Remember, your goal is to provide an accurate and reliable clinical note based solely on the information available to you. Accuracy is crucial in medical documentation, so avoid any form of hallucination or speculation.

10. Present your final clinical note without any additional commentary or explanations.  

11. If there is insufficient information provided to generate a complete note, simply write [not enough data]. Do not try to fill in gaps with outside knowledge.
  `;

  const instructions =
    noteTypeBodyType !== "EXAMPLE" ? templateInstructions : exampleInstructions;
  const message =
    noteTypeBodyType !== "EXAMPLE" ? templateMessage : exampleMessage;
  modelType = modelType ? modelType : "SONNET";

  return new Promise((resolve, reject) => {
    apiAskAssistantStream(
      instructions,
      message,
      modelType,
      (data) => {
        if (!firstMessageReceived) {
          setIsLoading(false);
          firstMessageReceived = true;
        }
        if (data === "Run completed") {
          resolve({ success: true, noteBody: noteBodyRef.current });
        } else {
          queueRef.current.push(data);
          processQueue(
            queueRef,
            isTypingRef,
            noteBodyRef,
            currentVisitId,
            currentNoteId,
            dispatch,
          );
        }
      },
      (error) => {
        console.error("Error generating note:", error);
        dispatch(
          setNotification({
            name: "ERROR",
            description: "Something went wrong. Try again!",
            status: "error",
            duration: 5000,
            isClosable: true,
          }),
        );
        reject({ success: false, error });
      },
    );
  });
};
