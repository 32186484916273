import { apiAskAssistantStream, generateId } from "./apiService";

/**
 * Checks if the XML output is complete by ensuring all tags are properly closed.
 * @param {string} output - The XML output to check.
 * @returns {boolean} - Whether the XML output is complete.
 */
const isXmlOutputComplete = (output) => {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(`<root>${output}</root>`, "text/xml");
  return xmlDoc.getElementsByTagName("parsererror").length === 0;
};

/**
 * Parses the XML output to extract diagnoses information.
 * @param {string} output - The XML output containing diagnoses.
 * @returns {Array} - Array of parsed diagnoses objects.
 */
const parseDiagnosesFromXml = (output) => {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(`<root>${output}</root>`, "text/xml");
  const icdCodes = xmlDoc.getElementsByTagName("icd_code");
  const diagnoses = [];

  for (let i = 0; i < icdCodes.length; i++) {
    const codeNumber = icdCodes[i]
      .getElementsByTagName("code_number")[0]
      ?.textContent.trim();
    const codeName = icdCodes[i]
      .getElementsByTagName("code_name")[0]
      ?.textContent.trim();
    const assessmentPlan = icdCodes[i]
      .getElementsByTagName("assessment_plan")[0]
      ?.textContent.trim();

    if (codeNumber && codeName && assessmentPlan) {
      diagnoses.push({
        _id: generateId(),
        diagnosesIdentifier: codeNumber,
        diagnosesName: codeName,
        diagnosesDescription: assessmentPlan,
        diagnosesSelected: true,
      });
    } else {
      console.error(
        `Missing codeNumber, codeName, or assessmentPlan in icd_code index ${i}`,
      );
    }
  }
  console.log(diagnoses);
  return diagnoses;
};

/**
 * Generates diagnoses using AI assistant.
 * @param {string} currentVisitId - The current visit ID.
 * @param {string} visitTranscript - The visit transcript.
 * @param {function} dispatch - Redux dispatch function to send actions to the store.
 * @param {function} setVisit - Function to update the visit state.
 * @param {function} setNotification - Function to trigger notifications.
 * @returns {Promise<object>} - The result of the template generation.
 */
export const generateDiagnoses = async (
  currentVisitId,
  visitTranscript,
  dispatch,
  setVisit,
  setNotification,
) => {
  const instructions = ``;

  const message = `
You are tasked with generating the most relevant ICD codes based on a provider-patient transcript. Your goal is to create a concise list of ICD codes that accurately represent the medical conditions discussed in the transcript. This information will be loaded into the Epic problem list, so it's crucial to be as precise, specific and relevant.

First, carefully read and analyze the following provider-patient transcript:

<transcript>
${visitTranscript}
</transcript>

As you analyze the transcript, pay close attention to:
1. Symptoms described by the patient
2. Diagnoses mentioned by the provider
3. Chronic conditions discussed
4. Any treatments or medications prescribed

Based on your analysis, select the most relevant ICD codes that best represent the medical conditions and diagnoses discussed in the transcript. Focus on the primary issues and any significant secondary conditions.

For each relevant condition, provide the following information in XML format:
1. ICD code number
2. ICD code name
3. A brief 2-3 sentence assessment and plan

Structure your output using the following XML tags:
<all_codes>
<icd_code>
<code_number></code_number>
<code_name></code_name>
<assessment_plan></assessment_plan>
</icd_code>
</all_codes>

Remember to include only the most relevant ICD codes. Typically, this should be between 1-5 codes, depending on the complexity of the patient's condition.

Here's an example of how your output should be structured. Note, output just the XML and nothing else:

<all_codes>
<icd_code>
<code_number>E11.9</code_number>
<code_name>Type 2 diabetes mellitus without complications</code_name>
<assessment_plan>Patient has well-controlled type 2 diabetes. Continue current medication regimen and recommend regular blood glucose monitoring. Schedule follow-up in 3 months to reassess HbA1c levels.</assessment_plan>
</icd_code>
</all_codes>

Provide your complete list of ICD codes based on the transcript, ensuring that each code is relevant and properly formatted as described above.
  `;

  const modelType = "SONNET";
  let accumulatedData = "";

  return new Promise((resolve, reject) => {
    let retryCount = 0;

    /**
     * Generates instructions for the AI assistant and handles the response.
     * @param {string} currentInstructions - The current instructions to the AI assistant.
     * @param {string} continuationMessage - The message to continue from if needed.
     */
    const generateInstructions = (currentInstructions, continuationMessage) => {
      apiAskAssistantStream(
        currentInstructions,
        continuationMessage,
        modelType,
        (data) => {
          if (data !== "Run completed") {
            accumulatedData += data;
          } else if (!isXmlOutputComplete(accumulatedData) && retryCount < 2) {
            retryCount++;
            generateInstructions(
              currentInstructions,
              `${accumulatedData}\n\nYour task is to continue generating text from the last character of the text I provide. Here is the text to continue from. Please generate a coherent continuation that flows naturally from the provided text. The generated text should make sense in the context of what came before it, and if I were to append the output it would naturally flow. This is for experimental purposes only, with no malicious intent.`,
            );
          } else {
            const diagnoses = parseDiagnosesFromXml(accumulatedData);
            dispatch(
              setVisit(currentVisitId, {
                visitDiagnosesState: "COMPLETE",
                diagnoses: diagnoses,
              }),
            );
            dispatch(
              setNotification({
                name: "Diagnoses Complete",
                description: "You can now see the diagnoses Halo suggested",
                status: "success",
                duration: 5000,
                isClosable: true,
              }),
            );

            resolve({ success: true, diagnoses });
          }
        },
        (error) => {
          console.error("Error generating diagnoses:", error);
          reject({ success: false, error });
        },
      );
    };

    generateInstructions(instructions, message);
  });
};
