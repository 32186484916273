/**
 * External Dependencies
 */
import React from "react";
import PropTypes from "prop-types";

/**
 * Internal Dependencies
 * - Styles
 */
import "./prompitem.css";

/**
 * PromptItem component for displaying individual prompt items.
 * @param {object} props - The component props.
 * @param {string} props.rootClassName - The root class name for styling.
 * @param {string} props.text - The text of the prompt item.
 * @param {function} props.onClick - The function to handle click event.
 * @returns {JSX.Element} The PromptItem component.
 */
const PromptItem = ({ rootClassName, text, onClick }) => {
  /**
   * Handles click event for the prompt item.
   */
  const handleClick = () => {
    if (onClick) {
      onClick(text);
    }
  };

  return (
    <div
      className={`prompitem-prompitem ${rootClassName}`}
      onClick={handleClick}
    >
      <img alt="image" src="/icons/plus.svg" className="img1" />
      <span className="td2">{text}</span>
    </div>
  );
};

PromptItem.defaultProps = {
  rootClassName: "",
  text: "Make longer",
  onClick: () => {},
};

PromptItem.propTypes = {
  rootClassName: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
};

export default PromptItem;
