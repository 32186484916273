/**
 * External Dependencies
 */
import React from "react";
import PropTypes from "prop-types";

/**
 * Internal Components
 */
import CircleIconLight from "../../shared/circleiconlight";

/**
 * Internal Dependencies
 * - Styles
 */
import "./createnewtemplateitem.css";

/**
 * CreateNewTemplateItem Component
 * @param {string} mainText - The main text for the item.
 * @param {string} subText - The subtext for the item.
 * @param {function} onClick - The click handler for the item.
 * @returns {JSX.Element} CreateNewTemplateItem component
 */
const CreateNewTemplateItem = ({ mainText, subText, onClick }) => {
  return (
    <>
      <div
        className="createnewtemplateitem-createnewtemplateitem"
        onClick={onClick}
      >
        <CircleIconLight
          rootClassName="circleiconlight-root-class-name2"
          imageSrc="/icons/plus2.svg"
        />
        <div className="createnewtemplateitem-textcontainer">
          <span className="td2">{mainText}</span>
          <span className="createnewtemplateitem-text1 tl1">{subText}</span>
        </div>
      </div>
      <div className="createnewtemplateitem-horizontalline"></div>
    </>
  );
};

CreateNewTemplateItem.defaultProps = {
  mainText: "Create a new template",
  subText: "Use an existing note as a template or create your own.",
  onClick: () => {},
};

CreateNewTemplateItem.propTypes = {
  mainText: PropTypes.string,
  subText: PropTypes.string,
  onClick: PropTypes.func,
};

export default CreateNewTemplateItem;
