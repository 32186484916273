/**
 * External Dependencies
 */
import React from "react";
import PropTypes from "prop-types";

/**
 * Internal Dependencies
 * - Styles
 */
import "./subbutton.css";

/**
 * SubButton Component
 * @param {string} image - Source URL for the button image.
 * @param {string} text - Text to display on the button.
 * @param {function} onClick - Click handler function for the button.
 * @param {boolean} textFirst - If true, display text before the image.
 * @param {string} rootClassName - Additional class name for the root element.
 * @returns {JSX.Element} SubButton component
 */
const SubButton = ({ image, text, onClick, textFirst, rootClassName }) => {
  return (
    <div className={`subbutton-subbutton ${rootClassName}`} onClick={onClick}>
      {textFirst ? (
        <>
          <span className="td2">{text}</span>
          <img alt="image" src={image} className="img1" />
        </>
      ) : (
        <>
          <img alt="image" src={image} className="img1" />
          <span className="td2">{text}</span>
        </>
      )}
    </div>
  );
};

SubButton.defaultProps = {
  image: "/icons/microphone.svg",
  text: "Add",
  onClick: () => {},
  textFirst: false,
  rootClassName: "",
};

SubButton.propTypes = {
  image: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  textFirst: PropTypes.bool,
  rootClassName: PropTypes.string,
};

export default SubButton;
